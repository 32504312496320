import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/_common/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnershipType, Citizenship, PersonType } from 'src/app/_enumeration/enums';
import { AppOwnership, Country, AutPerson, UserInfo } from 'src/app/_domain';
import { GlobalConstants } from 'src/app/common/global-constants';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AplService, DocumentService } from 'src/app/shared/_services';
import { AuthorityDetails } from 'src/app/_domain/authority.interface';
import { AuthorityService } from 'src/app/shared/_services/authority.service';
import { DocumentComponent } from 'src/app/shared/document/document.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PersonInfo } from '../../_domain/userinfo.interface';

export enum AuthType {
  volitaja = 'VOLITAJA',
  volitatud = 'VOLITATUD'
}

export enum AuthStatus {
  taotlev = 'TAOTLEV',
  kehtiv = 'KEHTIV',
  loppend = 'LOPPEND'
}

@Component({
  selector: 'app-authority-detail',
  templateUrl: './authority-detail.component.html',
  styleUrls: ['./authority-detail.component.scss']
})
export class AuthorityDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  ESTONIA_COUNTRY_ID = GlobalConstants.ESTONIA_COUNTRY_ID;
  @ViewChild('isikDetailTpl') isikDetailTpl: TemplateRef<any>;
  currentPerson: any;
  public personInfo: PersonInfo;
  modalRef2: BsModalRef;
  isModalLoading = false;

  isVolitajaLoading = false;
  isVolitatudLoading = false;

  PersonTypeEnum = PersonType;
  OwnershipTypeEnum = OwnershipType;
  authStatus = AuthStatus;

  env: string;
  mode = 'rw';
  volitusId: number;
  isLoading = false;

  countryMap = new Map<number, string>();
  countries: Country[] = [];

  currentVolitaja: AutPerson;
  currentVolitatud: AutPerson;

  isVolituseLismine = true;
  hasVolitaja = false;
  hasVolitatudIsik = false;

  appVolitusForm: FormGroup;
  volitusStatus: string;
  // main data
  currentVolitusData: AuthorityDetails;
  currentVolitajaFormData = {
    address: null,
    country: null,
    email: null,
    firstName: null,
    fractureParts: null,
    fractureWhole: null,
    idOrRegistryCode: null,
    name: null,
    personCountryId: null,
    personFirstName: null,
    personId: null,
    personName: null,
    personRegCode: null,
    personType: null,
    relationType: null,
    representedPersonId: null,
    autAuthorVesselsById: null
  };
  currentVolitatudFormData = {
    address: null,
    country: null,
    email: null,
    firstName: null,
    fractureParts: null,
    fractureWhole: null,
    idOrRegistryCode: null,
    name: null,
    personCountryId: null,
    personFirstName: null,
    personId: null,
    personName: null,
    personRegCode: null,
    personType: null,
    relationType: null,
    representedPersonId: null
  };
  currentVessels = [];
  allVolitajaVessels = [];
  volitatudVessels = [];

  /* Add isik modal conf */
  addIsikMode = 'rw';

  updateIsikModalB = false;
  currentOwnershipType: OwnershipType;
  currentOwnership: AppOwnership;
  citizenship = Citizenship.EST;
  currentAuthType: AuthType;
  @ViewChild('addEditIsikTbl') addEditIsikTbl: TemplateRef<any>;
  volitaja = AuthType.volitaja;
  volitatud = AuthType.volitatud;
  isCollapsed1 = false;
  isCollapsed2 = false;
  isCollapsed3 = false;
  isCollapsed4 = false;
  isCollapsed5 = false;

  volitusSubmitted = false;
  validToErrorMsg = null;
  volitusedOtsingUrl: string;
  modalTuhistamineConfirmRef: BsModalRef;
  tuhistamineReason = null;
  tuhistaVolitusSubbmit = false;
  @ViewChild('modalTuhistanVolituseTpl')
  modalTuhistanVolituseTpl: TemplateRef<any>;
  isKoopia = false;
  saveSuccess = false;

  @ViewChild('confirmModalTpl') confirmModalTpl: TemplateRef<any>;
  confirmModalRef: BsModalRef;

  hasSamePersonAlready = false;
  isOsalineVolitusMultiUnselectedError = false;

  // Document
  context = 'VOLITUS';
  currentDocId = null;
  currentDoc = null;
  docTableData = [];
  doctypes = [];
  doctypeGroups = [];
  doctypesByGroup = [];

  saveNewAadress = false;
  newAdress = '';
  saveNewEmail = false;
  newEmail = '';

  canEditAuthorization = true;

  /*
   CONSTRUCTOR
  */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private aplService: AplService,
    private authorityService: AuthorityService,
    private documentService: DocumentService
  ) {
    super();

    this.fieldLabels = {
      mandatorId: 'AUTH.VOLITAJA.TITLE',
      representorId: 'AUTH.VOLITATUD.TITLE',
      validTo: 'GLOBAL.KEHTIVUSE_LOPP',
      rightNewvessel: 'AUTH.UUE_LAEVA_REG',
      rightActivities: 'AUTH.LAEVADEGA_TOIMUNUD_TEGEVUSED',
      rightAuthorisation: 'AUTH.VOLITUSE_LISAMINE',
      rightSignature: 'AUTH.ALLKIRJASTAMINE',
      documentId: 'DOC.DOCUMENT'
    };
  }

  async ngOnInit() {
    this.saveSuccess = false;
    this.initLang();
    this.uiHelper.initSelectPicker();
    this.addSubscriptionToList(
      this.route.data.subscribe(data => {
        this.env = data.env;
        if (this.env === 'ik') {
          this.mode = 'ro';
        }
      })
    );

    this.createForm();

    const snapshot = this.route.snapshot;
    this.volitusId = snapshot.params.id ? +snapshot.params.id : null;
    const koopiaUrls = snapshot.url.filter(url => {
      return url.path === 'koopia';
    });

    await this.loadVolitus(this.volitusId);
    // Uue volituse koostamine olemasoleva pealt
    if (koopiaUrls.length > 0) {
      // reset values
      this.isKoopia = true;
      this.isVolituseLismine = true;
      // this.volitusId = null;

      // this.currentVolitusData = null;
      this.appVolitusForm.controls.validTo.setValue(null);
      this.appVolitusForm.controls.limitless.setValue(true);

      this.currentDocId = null;
      this.docTableData = [];
    } else {
      if (this.volitusId) {
        // Olemas olev volitus
        this.isVolituseLismine = false;
      } else {
        // Uus volitus
        this.isVolituseLismine = true;
      }
    }

    if (this.isVolituseLismine) {
      // uue volituse sisestamise vorm RW režiimis.
      this.mode = 'rw';
    } else {
      // kehtiva ja lõpetatud volituste korral - vorm RO režiimis
      this.mode = 'ro';
    }

    this.setLanguageAndCountryNames(this.lang);
    this.getDoctypesByContext();

    this.volitusedOtsingUrl = this.lang + '/' + this.env + '/volitused/otsing';

    this.appVolitusForm.controls.limitless.valueChanges.subscribe(value => {
      if (value) {
        this.appVolitusForm.controls.validTo.disable({ onlySelf: true, emitEvent: true });
      } else {
        this.appVolitusForm.controls.validTo.enable({ onlySelf: true, emitEvent: true });
      }
    });

    if (this.env === 'ik') {

      if (this.mode === 'ro') {
        const { currentUser, activeEntity } = this.getCurrentUserAndActiveEntity();

        // FIXME: relies on the userdata component, tab name & localstorage item is created / updated there
        const tabData = JSON.parse(localStorage.getItem('userDataTabMap'));
        const isTakenRepresentativeTab = tabData.some(item => item.tabName === 'USER.TAKEN_REPRESENTATIVE');

        // user is not himself
        if (activeEntity && activeEntity.id !== currentUser.autPersonId) {
          const params = 'status=VALID&mandator=' +
            encodeURIComponent(activeEntity.firstName + ' ' + activeEntity.name);

          this.userService.getUserMandators(params)
            .subscribe(response => {
              if (response.data && response.data.length) {
                const authId = this.findAuthId(response.data, currentUser);
                if (authId !== null) {
                  this.authorityService.getAuthoritiesDetails(authId)
                    .subscribe(res => {
                      const isRestricted = res.data.mandatorId === activeEntity.id &&
                        res.data.representorId === this.currentVolitatud.id;
                      this.canEditAuthorization = !isRestricted && !isTakenRepresentativeTab &&
                        (res.data.rightAuthorisation || res.data.unrestricted);
                    },
                      error => this.getLongMsgError(error.message));
                }
              }
            }, error => this.getLongMsgError(error.message));
        } else {
          // user is himself, but is not viewing his taken authorities.
          this.canEditAuthorization = !isTakenRepresentativeTab;
        }
      }

      if (this.mode === 'rw' && !this.isKoopia) {
        await this.fillAuthorizerData();
        // New representative is chosen
        this.addSubscriptionToList(this.userService.getNewRepresentativeEvent()
          .subscribe(() => {
            this.fillAuthorizerData();
          }));
      }
    }

    this.scrollToTop();
  }

  findAuthId(data, currentUser: UserInfo): number {
    let authId = null;
    data.forEach(item => {
      if (item.representorRegcode === currentUser.registerCode) {
        authId = item.id;
      }
    });
    return authId;
  }

  getCurrentUserAndActiveEntity() {
    const currentUser = this.userService.getUser();
    const activeEntity = currentUser.entities[currentUser.activeRoleEntity];

    return {
      currentUser,
      activeEntity
    };
  }

  async fillAuthorizerData() {
    const activeEntity = this.getCurrentUserAndActiveEntity().activeEntity;
    await this.getAuthoritiesVesselsByMandator(activeEntity.id);

    this.currentPerson = {
      personId: activeEntity.id,
      personRegCode: activeEntity.code,
      personCountryId: activeEntity.countryId,
      personType: activeEntity.personType,
      personName: activeEntity.name,
      personFirstName: activeEntity.firstName
    };

    this.hasVolitaja = true;
    this.isVolitajaLoading = false;

    Object.assign(this.currentVolitajaFormData, this.currentPerson);
  }

  createForm(): void {
    this.appVolitusForm = this.fb.group({
      id: [null],
      /*   validFrom: [null], */
      validTo: [null],
      limitless: [true],
      unrestricted: ['true'],

      rightSignature: [false],
      rightAuthorisation: [false],
      rightNewvessel: [false],
      rightActivities: [false],

      selectedVessel: [null]
    });
  }

  initFieldLabels() {
    if (this.fieldLabels) {
      // field labels for error messages
      this.translateServ.get(Object.values(this.fieldLabels)).subscribe(translatedLabelsList => {
        this.translationMap = translatedLabelsList;
      });
    }
  }

  getDefaultVolitajaFormData() {
    return {
      address: null,
      country: null,
      email: null,
      firstName: null,
      fractureParts: null,
      fractureWhole: null,
      idOrRegistryCode: null,
      name: null,
      personCountryId: null,
      personFirstName: null,
      personId: null,
      personName: null,
      personRegCode: null,
      personType: null,
      relationType: null,
      representedPersonId: null,
      autAuthorVesselsById: []
    };
  }

  getDefaultVolitatudFormData() {
    return {
      address: null,
      country: null,
      email: null,
      firstName: null,
      fractureParts: null,
      fractureWhole: null,
      idOrRegistryCode: null,
      name: null,
      personCountryId: null,
      personFirstName: null,
      personId: null,
      personName: null,
      personRegCode: null,
      personType: null,
      relationType: null,
      representedPersonId: null
    };
  }

  clearAll() {
    this.appVolitusForm.reset();
    this.appVolitusForm.controls.unrestricted.patchValue('true');
    this.currentVolitusData = {
      id: null,
      autPersonByMandatorId: null,
      autPersonByRepresentorId: null
    } as AuthorityDetails;
    this.currentVolitajaFormData = this.getDefaultVolitajaFormData();
    this.currentVolitatudFormData = this.getDefaultVolitatudFormData();
    if (this.currentVolitusData) {
      this.currentVolitaja = this.currentVolitusData.autPersonByMandatorId;
      this.currentVolitatud = this.currentVolitusData.autPersonByRepresentorId;
    }
    this.hasVolitaja = false;
    this.hasVolitatudIsik = false;
  }

  async loadVolitus(volitusId: number) {
    if (volitusId) {
      await this.getAuthoritiesDetails(volitusId);
    } else {
      this.currentVolitusData = {
        id: null,
        autPersonByMandatorId: null,
        autPersonByRepresentorId: null
      } as AuthorityDetails;
    }
    if (this.currentVolitusData) {
      this.currentVolitaja = this.currentVolitusData.autPersonByMandatorId;
      this.currentVolitatud = this.currentVolitusData.autPersonByRepresentorId;
    }
  }

  /*
    "Taotletav", kui volitusel puudub kehtivuse algus, st aut_authorisation.valid_from on tühi (4.arendusetapis selliseid ei teki)
    "Kehtiv", kui aut_authorisation.valid_from <= hetke aeg ning
    ( aut_authorisation.valid_to on tühi või aut_authorisation.valid_to >= hetke aeg).
    "Lõppenud", kui aut_authorisation.valid_to <  hetke aeg.
  */
  getVolitusStatus(): string {
    if (this.currentVolitusData && this.currentVolitusData.id) {
      let outStatus = null;
      if (!this.currentVolitusData.validFrom) {
        outStatus = AuthStatus.taotlev;
      } else if (
        new Date(this.currentVolitusData.validFrom) <= new Date() &&
        (!this.currentVolitusData.validTo || new Date(this.currentVolitusData.validTo) >= new Date())
      ) {
        outStatus = AuthStatus.kehtiv;
      } else if (new Date(this.currentVolitusData.validFrom) < new Date()) {
        outStatus = AuthStatus.loppend;
      }
      return outStatus;
    }
  }

  clearKehtivusLopp() {
    if (this.appVolitusForm.value.limitless) {
      this.appVolitusForm.controls.validTo.patchValue(null);
    }
  }

  setUnrestricted(value: string) {
    this.appVolitusForm.controls.unrestricted.patchValue(value);
    this.uiHelper.refreshSelectPicker();
  }

  openCreateOwnershipModal(ownershipType: OwnershipType, ownership: AppOwnership, authType: AuthType): void {
    this.currentAuthType = authType;
    this.currentOwnershipType = ownershipType;
    this.currentOwnership = ownership;
    this.modalRef = this.modalService.show(
      this.addEditIsikTbl,
      Object.assign({}, { class: 'modal-lg' }, this.modalNoBackdropConfig)
    );
  }

  saveOrGetIsikMap(map: any) {
    // console.log('map', map);
    if (map.autPerson && (map.autPerson.addresses as string[]).indexOf(map.formData.value.address) === -1) {
      this.saveNewAadress = true;
      this.newAdress = map.formData.value.address;
    }
    if (map.autPerson && (map.autPerson.emails as string[]).indexOf(map.formData.value.email) === -1) {
      this.saveNewEmail = true;
      this.newEmail = map.formData.value.email;
    }
    this.saveOrGetIsik(map.formData);
  }

  async saveUserContact(id: number) {
    if (id && this.saveNewAadress && this.newAdress && this.newAdress.length > 0) {
      try {
        await this.userService.addContactToUser(id, 'AADRESS', this.newAdress).toPromise();
      } catch (error) {
        this.showDbErrorMessage('ERROR.UPDATE_DATA', error);
      }
    }
    if (id && this.saveNewEmail && this.newEmail && this.newEmail.length > 0) {
      try {
        await this.userService.addContactToUser(id, 'EMAIL', this.newEmail).toPromise();
      } catch (error) {
        this.showDbErrorMessage('ERROR.UPDATE_DATA', error);
      }
    }
  }

  async saveOrGetIsik(form: FormGroup) {
    // console.log('saveOrGetIsik', form);
    const isForeigner = form.value.personCountryId !== this.ESTONIA_COUNTRY_ID ? true : false;
    if (isForeigner) {
      this.citizenship = Citizenship.FGN;
      if (this.updateIsikModalB) {
        // this.updateOwnership2(form);
      } else {
        if (form.value.personId) {
          await this.addIsikToPage(form.value);
        } else {
          await this.saveAuthPerson(form);
        }
      }
    } else {
      await this.addIsikToPage(form.value);
    }
    this.closeModal();
  }

  saveAuthPerson(personFormData: FormGroup) {
    const currentEmail = personFormData.value.email
      ? [{ id: null, contactText: personFormData.value.email, contactType: 'EMAIL' }]
      : [];
    const currentAadress = personFormData.value.address
      ? [{ id: null, contactText: personFormData.value.address, contactType: 'AADRESS' }]
      : [];

    const outputData = {
      personType: personFormData.value.personType,
      firstName: personFormData.value.firstName,
      name: personFormData.value.name,
      countryId: personFormData.value.country.id,
      registerCode: personFormData.value.idOrRegistryCode,
      contacts: [...currentEmail, ...currentAadress]
    };

    this.userService.saveAuthPerson(outputData).subscribe(
      async (response: any) => {
        if (response.success) {
          const formValues = {
            personId: response.data.id,
            personFirstName: response.data.firstName,
            personName: response.data.name,
            personRegCode: response.data.registerCode,
            personCountryId: response.data.countryId,
            personType: response.data.personType,
            address: response.data.addresses.join(', '),
            email: response.data.emails.join(', ')
          };
          this.addIsikToPage(formValues);
          await this.saveUserContact(formValues.personId);
          this.closeModal();
          /*  this.getLongMsgSuccess('APL.REQ_OWN_SAVE_SUCCESS'); */
        }
      },
      () => {
        this.translateServ.get('ERROR.CREATE_DATA').subscribe((text: string) => {
          this.getLongMsgError(text);
        });
      }
    );
  }

  async addIsikToPage(formValues: any) {
    // await this.updateAuthPerson(formValues);
    await this.saveUserContact(formValues.personId);
    this.initFormDataByAddIsikComp(formValues);
  }

  async updateAuthPerson(user) {
    const outputData = {
      id: user.personId,
      firstName: user.personFirstName,
      name: user.personName,
      notificationEmail: user.notificationEmail
    };
    this.addSpinnerLayer();
    try {
      await this.userService.updatePerson(user).toPromise();
      this.removeSpinnerLayer();
    } catch (error) {
      this.removeSpinnerLayer();
      this.showDbErrorMessage('ERROR.UPDATE_DATA', error.message);
    }
  }

  addSelectedVessel(selectedVessel: any) {
    if (selectedVessel && selectedVessel.id) {
      this.volitatudVessels = [selectedVessel, ...this.volitatudVessels];
      this.currentVessels = this.currentVessels.filter(item => {
        return item.id !== selectedVessel.id;
      });
      this.appVolitusForm.controls.selectedVessel.patchValue({ id: null, vesselName: '-' });
      this.uiHelper.refreshSelectPicker();
    }
  }

  removeSelectedVessel(removedVessel) {
    if (removedVessel && removedVessel.id) {
      this.volitatudVessels = this.volitatudVessels.filter(item => {
        return item.id !== removedVessel.id;
      });
      this.currentVessels = [...this.currentVessels, removedVessel];
      this.appVolitusForm.controls.selectedVessel.patchValue({ id: null, vesselName: '-' });
      this.uiHelper.refreshSelectPicker();
    }
  }

  /*

  */
  setLanguageAndCountryNames(lang: string): void {
    // this.lang = Language[lang.toUpperCase()];
    this.subscriptions.push(
      this.aplService.getCountries().subscribe((response: Country[]) => {
        this.setAndSortCountries(response);
        response.forEach(country => {
          this.countryMap.set(country.id, country.name[lang]);
        });
      })
    );
  }

  setAndSortCountries(countries: Country[]): void {
    countries.forEach((country: Country) => {
      if (Object.values(country.name).find(x => x === '' || x === '-') === undefined) {
        // x.toUpperCase() === 'EESTI' ||
        this.countries.push(country);
      }
    });
    if (countries.length > 0) {
      Object.keys(countries[0].name).forEach(key => {
        if (key.toUpperCase() === this.lang) {
          this.countries.sort((a: Country, b: Country) =>
            a.name[key] > b.name[key] ? 1 : b.name[key] > a.name[key] ? -1 : 0
          );
        }
      });
    }
  }
  initFormDataByAddIsikComp(componentValues: any) {
    if (this.currentAuthType === AuthType.volitaja) {
      this.currentVolitajaFormData = componentValues;
      this.hasVolitaja = true;
      // get ships
      this.getAuthoritiesVesselsByMandator(this.currentVolitajaFormData.personId);
    }
    if (this.currentAuthType === AuthType.volitatud) {
      this.currentVolitatudFormData = componentValues;
      this.hasVolitatudIsik = true;
    }
  }

  refreshSelectPicker() {
    this.uiHelper.refreshSelectPicker();
  }

  async getAuthoritiesDetails(id: number) {
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    try {
      this.isVolitajaLoading = true;
      this.isVolitatudLoading = true;
      const res = await this.authorityService.getAuthoritiesDetails(id).toPromise();
      if (res.success === true) {
        this.currentVolitusData = res.data;
        if (this.currentVolitusData) {
          this.setFormGroupFields(this.appVolitusForm.controls, this.currentVolitusData);

          this.appVolitusForm.controls.limitless.patchValue(!this.appVolitusForm.value.validTo);
          /*  this.appVolitusForm.controls.unrestricted.patchValue(this.currentVolitusData.unrestricted);
           this.appVolitusForm.controls.rightAuthorisation.patchValue(this.currentVolitusData.rightAuthorisation);
           this.appVolitusForm.controls.rightNewvessel.patchValue(this.currentVolitusData.rightNewvessel);
           this.appVolitusForm.controls.rightActivities.patchValue(this.currentVolitusData.rightActivities);
           this.appVolitusForm.controls.rightSignature.patchValue(this.currentVolitusData.rightSignature); */

          // Volitaja
          this.currentVolitaja = this.currentVolitusData.autPersonByMandatorId;
          this.currentVolitajaFormData = this.parseIsikToForm(this.currentVolitusData.autPersonByMandatorId);

          if (this.currentVolitajaFormData) {
            this.hasVolitaja = true;
            this.addIsikToPage(this.currentVolitajaFormData);
            if (
              this.currentVolitusData.autPersonByMandatorId &&
              this.currentVolitusData.autPersonByMandatorId.id
            ) {
              this.currentVolitajaFormData.personId = this.currentVolitusData.autPersonByMandatorId.id;
              await this.getAuthoritiesVesselsByMandator(this.currentVolitusData.autPersonByMandatorId.id);
              if (
                this.currentVolitusData.autAuthorVesselsById &&
                this.currentVolitusData.autAuthorVesselsById.length > 0
              ) {
                this.currentVolitusData.autAuthorVesselsById.forEach(vessel => {
                  this.addSelectedVessel(vessel.vesVesselByVesselId);
                });
              }
            }
          }
          this.isVolitajaLoading = false;
          // Volitatud
          this.currentVolitatud = this.currentVolitusData.autPersonByRepresentorId;

          this.currentVolitatudFormData = this.parseIsikToForm(
            this.currentVolitusData.autPersonByRepresentorId
          );
          if (this.currentVolitatudFormData) {
            this.hasVolitatudIsik = true;
            this.addIsikToPage(this.currentVolitatudFormData);
            if (
              this.currentVolitusData.autPersonByRepresentorId &&
              this.currentVolitusData.autPersonByRepresentorId.id
            ) {
              this.currentVolitatudFormData.personId = this.currentVolitusData.autPersonByRepresentorId.id;
            }
          }
          this.isVolitatudLoading = false;
          this.volitusStatus = this.getVolitusStatus();
          // get document
          this.currentDocId = this.isKoopia ? null : this.currentVolitusData.documentId;
          if (this.currentDocId) {
            await this.getDocumentTableData();
          }
        } else {
          this.isVolitajaLoading = false;
          this.isVolitatudLoading = false;
          this.clearAll();
        }
      } else {
        this.isVolitajaLoading = false;
        this.isVolitatudLoading = false;
        this.showDbErrorMessage(defaultErrorMsgLabel, res);
      }
    } catch (error) {
      this.isVolitajaLoading = false;
      this.isVolitatudLoading = false;
      this.showDbErrorMessage(defaultErrorMsgLabel, error);
    }
  }

  parseIsikToForm(values: AutPerson): any {
    if (!values) {
      return null;
    }
    const addressStr = values.addresses.join(', ');
    const emailStr = values.emails.join(', ');
    return {
      personCountryId: values.countryId,
      personType: values.personType,
      personRegCode: values.registerCode,
      personFirstName: values.firstName,
      personName: values.name,
      address: addressStr,
      email: emailStr
    };
  }

  async getAuthoritiesVesselsByMandator(id: number) {
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    try {
      const res = await this.authorityService.getAuthoritiesVesselsByMandator(id).toPromise();
      if (res.success === true) {
        let vessels = res.data
          ? res.data.filter((thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i)
          : [];
        // sort
        vessels = vessels.sort((a, b) => a.vesselName.localeCompare(b.vesselName));

        this.currentVessels = [{ id: null, vesselName: '-' }, ...vessels];
        this.allVolitajaVessels = vessels;
        this.uiHelper.refreshSelectPicker();
      } else {
        this.showDbErrorMessage(defaultErrorMsgLabel, res);
      }
    } catch (error) {
      this.showDbErrorMessage(defaultErrorMsgLabel, error);
    }
  }

  /*
    DOCUMENT
  */
  async getDocumentTableData() {
    const defaultErrorMsgLabel = 'DOC.LOAD_FAIL';
    if (this.currentDocId) {
      try {
        const docRes = await this.documentService.getDocumentView(this.currentDocId).toPromise();
        if (docRes.success) {
          /* const currentDocTypes = this.doctypes.filter(type => {
            return type.code === res.data.docType;
          }); */
          // res.data.descr = currentDocTypes[0].descr;
          this.currentDoc = docRes.data;
          this.docTableData = docRes.data ? [docRes.data] : [];
        } else {
          this.showDbErrorMessage(defaultErrorMsgLabel, docRes);
        }
      } catch (error) {
        this.showDbErrorMessage(defaultErrorMsgLabel, error);
      }
    }
  }

  openDocModal(docId?: number) {
    const initialState = {
      context: this.context,
      docId: docId ? docId : null,
      mode: this.mode,
      env: this.env,
      // lisamine toimib siin siin väljakutsumie juures
      saveDocumentOutsideComponent: true,
      docType: 20002,
      // prefill values
      preFillInputs: {
        docType: 20002,
        issuerId: this.currentDoc ? this.currentDoc.issuerId : null // TODO VTA id should be global constant
        // issueDate: this.appDossierResultsForm.value.endDate
      }
      // inspectionId: this.inspection.id
    };
    // console.log('openDocModal ', initialState);
    this.bsModalRef = this.modalService.show(DocumentComponent, {
      initialState,
      backdrop: false,
      ignoreBackdropClick: true
    });
    /*
      Subscriptions
    */
    // save
    this.addSubscriptionToList(
      this.bsModalRef.content.save.subscribe(
        res => {
          this.getIdAndCallSaveDocument(res);
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
    // removeFile
    this.addSubscriptionToList(
      this.bsModalRef.content.removeFileEvent.subscribe(
        res => {
          if (res.success) {
            // refresh table
            this.getDocumentTableData();
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  getIdAndCallSaveDocument(res) {
    // console.log('getIdAndCallSaveDocument ', res);
    const defaultErrorMsgLabel = 'DOC.ADD_FAILED';
    // res is { success : true, doumentData: docDocument, fileData: this.file}
    if (res.success) {
      this.saveDocument(res, null);
    }
  }

  saveDocument(res: any, docId?: number) {
    if (docId) {
      res.doumentData.id = docId;
    }
    const defaultErrorMsgLabel = 'DOC.ADD_FAILED';
    const saveUpdateLabel = res && res.doumentData && res.doumentData.id ? 'DOC.UPDATED' : 'DOC.ADDED';
    this.addSubscriptionToList(
      this.documentService.saveDocument(res.doumentData, res.fileData).subscribe(
        response => {
          if (response.success) {
            this.getLongMsgSuccess(saveUpdateLabel);
            // console.log('saveDocument', response);
            this.currentDocId = response.data;
            this.getDocumentTableData();
          } else {
            this.showDbErrorMessage(defaultErrorMsgLabel, response, true);
          }
          this.bsModalRef.hide();
        },
        error => {
          this.showDbErrorMessage(defaultErrorMsgLabel, error, true);
          this.bsModalRef.hide();
        }
      )
    );
  }

  deleteDocument(docId: number, showMsg = true, msgLabel = 'GLOBAL.DELETE_SUCCESS') {
    this.addSubscriptionToList(
      this.documentService.deleteCompactDocument(docId).subscribe(
        response => {
          if (response.success === true) {
            if (showMsg) {
              this.getLongMsgSuccess(msgLabel);
            }
            this.docTableData = [];
            this.currentDocId = null;
            // this.getDocumentTableData();
          } else {
            this.getLongMsgError('ERROR.DELETE_DATA');
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeAllFromList();
    if (this.currentDocId) {
      // console.log('ngOnDestroy delete doc', this.currentDocId);
      if (!this.volitusId && !this.saveSuccess) {
        this.deleteDocument(this.currentDocId, true, 'AUTH.UNSAVED_VOLITUS_DOC_DELETE_SUCCESS');
      }
    }
  }

  getDoctypesByContext() {
    this.addSubscriptionToList(
      this.documentService.getDocTypes(this.context).subscribe(
        response => {
          this.doctypes = response.data.docTypes;
          // console.log('this.doctypes ', this.doctypes);
          const groups = response.data.docTypes.map(d => d.docDoctypeGroupByGroupCode);
          this.doctypeGroups = groups.reduce((acc, current) => {
            const result = acc.find(({ code }) => code === current.code);
            if (!result) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.doctypesByGroup = response.data.docTypes.reduce((acc, current) => {
            const key = current.groupCode;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(current);
            return acc;
          }, {});
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  /*
    BUTTONS
  */
  cancleVolitus() {
    /* Delete file */
    if (this.docTableData.length > 0) {
      this.deleteDocument(this.docTableData[0].id, false);
    }
    this.routingLocation.back();
  }

  /*
    Volituse VALIDEERIMINE ja KINNITAMINE
  */
  async saveVolitus() {
    if (
      this.hasVolitaja === true &&
      this.hasVolitatudIsik === true &&
      this.docTableData &&
      this.currentVolitajaFormData.personId !== this.currentVolitatudFormData.personId
    ) {
      this.volitusSubmitted = true;
      if (this.docTableData && this.docTableData.length > 0) {
        this.currentVolitusData.documentId = this.docTableData[0].id;
      }
      if (this.currentVolitajaFormData.personId) {
        this.currentVolitusData.mandatorId = this.currentVolitajaFormData.personId;
      }
      if (this.currentVolitatudFormData.personId) {
        this.currentVolitusData.representorId = this.currentVolitatudFormData.personId;
      }
      if (this.appVolitusForm.controls.validTo.value !== null) {
        this.validToErrorMsg = this.isFormValueDateInFuture('validTo', this.appVolitusForm);
        if (this.validToErrorMsg === null) {
          this.currentVolitusData.validTo =
            this.appVolitusForm.controls.validTo.value !== null
              ? this.datePipe.transform(this.appVolitusForm.controls.validTo.value, this.DB_DATE_FORMAT)
              : null;
          this.validToErrorMsg = null;
        }
      } else if (this.appVolitusForm.controls.limitless.value === false) {
        this.validToErrorMsg = 'ERROR.REQUIRED';
      } else {
        this.currentVolitusData.validTo = null;
      }
      this.currentVolitusData.unrestricted =
        this.appVolitusForm.controls.unrestricted.value === 'true' ||
        this.appVolitusForm.controls.unrestricted.value === true;

      this.currentVolitusData.rightSignature = this.appVolitusForm.controls.rightSignature.value;
      this.currentVolitusData.rightAuthorisation = this.appVolitusForm.controls.rightAuthorisation.value;
      this.currentVolitusData.rightNewvessel = this.appVolitusForm.controls.rightNewvessel.value;
      this.currentVolitusData.rightActivities = this.appVolitusForm.controls.rightActivities.value;

      this.isOsalineVolitusMultiUnselectedError =
        !this.currentVolitusData.unrestricted &&
        this.currentVolitusData.rightSignature === false &&
        this.currentVolitusData.rightAuthorisation === false &&
        this.currentVolitusData.rightNewvessel === false &&
        this.currentVolitusData.rightActivities === false;

      // vessels
      const currentSelectedV =
        this.volitatudVessels && this.volitatudVessels.length > 0
          ? (this.volitatudVessels.map(item => item.id) as number[])
          : this.allVolitajaVessels.map(item => item.id);

      this.currentVolitusData.vesselIds =
        this.currentVolitusData.unrestricted === false && this.currentVolitusData.rightActivities === true
          ? currentSelectedV
          : [];

      // console.log(this.currentVolitusData);

      if (
        this.validToErrorMsg === null &&
        ((this.docTableData.length && this.docTableData[0].fileOriginName) || this.env === 'ik') &&
        this.isOsalineVolitusMultiUnselectedError === false
      ) {
        try {
          /*
           VALIDEERIMINE
           */
          const validateRes = await this.authorityService
            .validateAuthorities(this.currentVolitusData)
            .toPromise();
          if (validateRes.success === true) {
            this.hasSamePersonAlready = validateRes.data;
            this.openConfirmWarning();
          } else {
            this.getLongMsgError('AUTH.VALIDATE_ERROR');
          }
        } catch (error) {
          this.showDbErrorMessage('AUTH.VALIDATE_ERROR', error, this.fieldLabels, true, this.appVolitusForm);
        }
      } else {
        this.getLongMsgError('ERROR.FORM_INVALID');
      }
    }
  }

  /*
       KINNITAMINE
  */
  async kinnitaVolitus() {
    try {
      const kinnitusRes = await this.authorityService.kinnitaAuthorities(this.currentVolitusData).toPromise();
      if (kinnitusRes) {
        this.getLongMsgSuccess('GLOBAL.CONFIRM_SUCCESS');

        this.saveSuccess = true;
        this.closeConfirmModal();
        // this.goToCurrentVolitus(kinnitusRes.data.id);
        if (this.env === 'mk') {
          this.goToOtsing();
        } else {
          this.goToTakenAuthorizations();
        }
      } else {
        this.closeConfirmModal();
        this.getLongMsgError('ERROR.CONFIRM');
      }
    } catch (error) {
      this.closeConfirmModal();
      this.showDbErrorMessage('ERROR.CONFIRM', error, this.fieldLabels, true, this.appVolitusForm);
    } // kinnitus end
  }

  openConfirmWarning() {
    this.confirmModalRef = this.modalService.show(
      this.confirmModalTpl,
      Object.assign(
        {},
        { class: this.hasSamePersonAlready ? 'modal-lg' : 'modal-sm' },
        this.modalNoBackdropConfig
      )
    );
  }

  confirmWarning() {
    this.kinnitaVolitus();
  }

  closeConfirmModal() {
    this.confirmModalRef.hide();
  }

  /*
    TÜHISTAN VOLITUSE
  */

  openVolituseTuhistamineModal() {
    this.tuhistamineReason = null;
    this.modalTuhistamineConfirmRef = this.modalService.show(
      this.modalTuhistanVolituseTpl,
      Object.assign({}, { class: 'modal-lg' }, this.modalNoBackdropConfig)
    );
  }

  closeTuhistamineConfirmModal() {
    this.modalTuhistamineConfirmRef.hide();
  }

  async tuhistaVolitus() {
    this.tuhistaVolitusSubbmit = true;
    if (this.tuhistamineReason && this.tuhistamineReason.length > 0) {
      try {
        const tyhistamineRes = await this.authorityService
          .cancelAuthorities(this.volitusId, this.tuhistamineReason)
          .toPromise();
        if (tyhistamineRes) {
          this.getLongMsgSuccess('GLOBAL.CANCEL_SUCCESS');
          this.tuhistaVolitusSubbmit = false;
          this.closeTuhistamineConfirmModal();
          // this.goToCurrentVolitus(this.volitusId);

          location.reload();
        }
      } catch (error) {
        this.showDbErrorMessage('ERROR.CANCEL_DATA', error);
      }
    }
  }

  uueVolituseKoostamineTeisePealt() {
    this.goToCurrentVolitusKoopia(this.volitusId);
  }

  /*
    ROUTING TO DIFFERENT URL
  */

  goToOtsing() {
    this.router.navigate([this.lang + '/' + this.env + '/volitused/otsing']);
  }

  goToNewVolitus() {
    this.router.navigate([this.lang + '/' + this.env + '/volitused/volitus']);
  }

  goToCurrentVolitus(id) {
    this.router.navigate([this.lang + '/' + this.env + '/volitused/volitus/' + id]);
  }

  goToCurrentVolitusKoopia(id) {
    const pathVar = this.env === 'mk' ? '/volitused/volitus/' : '/minu/volitused/volitus/';
    this.router.navigate([this.lang + '/' + this.env + pathVar + id + '/koopia']);
  }

  goToTakenAuthorizations() {
    this.router.navigate([this.lang + '/' + this.env + '/minuandmed']);

  }

  async openModal2(person) {
    if (!this.isModalLoading) {
      this.isModalLoading = true;
      // open modal
      this.currentPerson = person.personId
        ? person
        : {
          personRegCode: person.registerCode,
          personCountryId: person.countryId,
          personType: person.personType,
          personName: person.name,
          personFirstName: person.firstName
        };
      try {
        const info = await this.userService
          .loadPerson(person.personId ? person.personId : person.id)
          .toPromise();
        this.personInfo = info;
      } catch (error) {
        this.getLongMsgError(error.message);
      }
      this.modalRef2 = this.modalService.show(this.isikDetailTpl, Object.assign({}, { class: 'modal-lg' }));
      this.isModalLoading = false;
    }
  }

  closeModal2() {
    this.modalRef2.hide();
  }
}
