import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable, observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_ENDPOINT } from '../../app.constants';
import { ObligationSearch, SearchObligations } from 'src/app/_domain/obligation.interface';

@Injectable({
  providedIn: 'root'
})
export class ObligationService {
  constructor(private http: HttpClient) { }

  searchPayments(formData: ObligationSearch) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/fin/paymentsearch', formData)
      .pipe(map((response: any) => response));
  }

  searchObligations(formData: SearchObligations, parms: string) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/statefee/search' + parms, formData)
      .pipe(map((response: any) => response));
  }

  createStateFee(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/application/create-statefee?appVersionId=' + id, {})
      .pipe(map((response: any) => response));
  }

  searchVesselFees(formData: SearchObligations, id: number, parms: string) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/vessel/statefee/' + id + parms, formData)
      .pipe(map((response: any) => response));
  }

  getAppStateFee(id: number, parms: string) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/application/statefee/' + id, {})
      .pipe(map((response: any) => response));
  }

  getPayments(id: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/finfee/payments/' + id)
      .pipe(map((response: any) => response));
  }

  getFeePayments(id: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/fin/feepayments/' + id)
      .pipe(map((response: any) => response));
  }

  deletePayment(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/fin/delete-payment/' + id, {})
      .pipe(map((response: any) => response));
  }

  deleteFeePayment(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/finfee/delete-payment/' + id, {})
      .pipe(map((response: any) => response));
  }

  addPayments(id: number, ids: number[]) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/finfee/add-payments/' + id, ids)
      .pipe(map((response: any) => response));
  }
  getUnpayedFinFee(page: number, size: number, sortBy: string, sortDir: string) {

    sortBy = (sortBy) ? sortBy : '';
    sortDir = (sortDir) ? sortDir.toString() : '';

    const params = new HttpParams()
      .set('arcived', 'false')
      .set('page', page.toString())
      .set('size', size.toString())
      .set('sort', sortBy + ',' + sortDir);

    return this.http
      .get<any>(API_ENDPOINT + 'api/finfee/active-person/not-payed', { params })
      .pipe(map((response: any) => response));
  }
}
