import { NgControl } from '@angular/forms';
import { Directive, Input, ElementRef, OnInit, OnChanges, SimpleChanges, HostBinding } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-datepicker[disableControl]'
})
export class DisableControlDirective  {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  @Input()
  public disabled: string;

  constructor(private ngControl: NgControl, private elem: ElementRef) {
  }

}
