import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaginationInstance } from 'ngx-pagination';

import { BaseComponent } from '../shared/_common/base/base.component';
import {
  ShipService,
  UserService,
  LangswitchService,
  AplService,
  ClassifierService
} from '../shared/_services';
import { UserInfo, Apl, AplSearch, Ship, ClassificatorObjectElement, UserRole } from '../_domain';
import { DatePipe } from '@angular/common';
import { DateConstant } from '../shared/_utils/date';
import { TableSessionService } from '../shared/_services/table-session.service';
import { ObligationService } from '../shared/_services/obligation.service';
import { Statefee, StatefeeSearch } from '../_domain/statefee.interface';

@Component({
  selector: 'app-my-stuff',
  templateUrl: './my-stuff.component.html',
  styleUrls: ['./my-stuff.component.scss'],
  providers: [DatePipe]
})
export class MyStuffComponent extends BaseComponent implements OnInit, OnDestroy {
  isMainPage: boolean;
  currentEnv: string;

  notFinishedAplLoading = false;
  unpaidFeesLoading = false;
  shipsLoading = false;

  public getTaotlusedSubject = new Subject<Apl>();
  clListItemMapByCode: Map<string, ClassificatorObjectElement>;
  isLaevRiigihalduseksVisible = false;
  currentTaotlus: Apl;
  currentTaotluseLiik: number;
  currentTaotluseNimi: string;
  applicantId: number; // LAB abimuutuja (currentUser?)

  activeRoleEntity: UserRole;
  currentUser: UserInfo;
  userInfo: UserInfo;

  modalRef: BsModalRef;
  dialogContentTemplate: TemplateRef<any>;
  loadingServicesCount = 0;

  // taotlused
  myAplTablePaginationData = {
    page: 0,
    size: 3
  };
  myShipTablePaginationData = {
    page: 0,
    size: 3
  };
  myUnpayedFinFeePaginationData = {
    page: 0,
    size: 3
  };
  // aplSearch: AplSearch;
  isSearching: boolean;
  aplSearchResults: Apl[];
  shipSearchResults: Ship[];

  today: Date;
  defaultSortBy = 'compSubDate';
  defaultSortDir = 'asc';
  // pagination
  page: number;
  size: number;
  lang: string;

  aplDetailRoutePath: string;
  shipDetailRoutePath: string;

  public aplPagConf: PaginationInstance;
  public shipPagConf: PaginationInstance;
  public unpayedFinFeesPageConf: PaginationInstance;

  unpayedFinFees: Statefee[];

  @ViewChild('confirmToimingContent21005Tpl') confirmToimingContent21005Tpl: TemplateRef<
    any
  >;
  @ViewChild('confirmToimingContent21004Tpl') confirmToimingContent21004Tpl: TemplateRef<
    any
  >;
  @ViewChild('confirmToimingContentOtherTpl') confirmToimingContentOtherTpl: TemplateRef<
    any
  >;

  aplSearch: AplSearch = {
    sortBy: 'compSubDate',
    sortDir: 'desc',
    page: 0,
    size: 10
  };

  finfeeSearch: StatefeeSearch = {
    sortBy: 'created',
    sortDir: 'desc',
    page: 0,
    size: 3
  };
  constructor(
    private shipService: ShipService,
    private aplService: AplService,
    private classifierService: ClassifierService,
    private router: Router,
    private route: ActivatedRoute,
    private tableSessionService: TableSessionService,
    private obligationService: ObligationService
  ) {
    super();
    this.isSearching = false;
  }

  ngOnInit() {
    this.addSubscriptionToList(this.route.data.subscribe(data => {
      this.isMainPage = data.isMainPage;
      this.currentEnv = data.env;

      this.aplDetailRoutePath = this.isMainPage === true ? './taotlused/taotlus/' : '../taotlused/taotlus/';
      this.shipDetailRoutePath = this.isMainPage === true ? './laevad/laev/' : '../laevad/laev/';
      this.today = new Date();
    }));

    this.page = 0;
    this.size = 10; // TODO võtta konfist - tulemuste arv leheküljel */
    /* LAB: lisasin paginaatori, spekis on nõutud */
    const aplParam = this.initTableParamsFromSession('aplTableId');
    if (!aplParam) {
      this.aplPagConf = {
        id: 'aplTableId',
        itemsPerPage: this.myAplTablePaginationData.size,
        currentPage: this.myAplTablePaginationData.page + 1,
        totalItems: 5
      };
    } else {
      this.aplPagConf = aplParam.pageConf;
    }

    const shipParam = this.initTableParamsFromSession('shipTableId');
    if (!shipParam) {
      this.shipPagConf = {
        id: 'shipTableId',
        itemsPerPage: this.myShipTablePaginationData.size,
        currentPage: this.myShipTablePaginationData.page + 1,
        totalItems: 0
      };
    } else {
      this.shipPagConf = shipParam.pageConf;
    }

    const unpayedFinFeeParams = this.initTableParamsFromSession('unpayedFinFeesTableId');
    if (!unpayedFinFeeParams) {
      this.unpayedFinFeesPageConf = {
        id: 'unpayedFinFeesTableId',
        itemsPerPage: this.myUnpayedFinFeePaginationData.size,
        currentPage: this.myUnpayedFinFeePaginationData.page + 1,
        totalItems: 0
      };
    } else {
      this.unpayedFinFeesPageConf = unpayedFinFeeParams.pageConf;
    }
    // init table
    const userInfo = this.userService.getUser();
    if (userInfo !== null && userInfo !== undefined) {
      this.initTables(userInfo);
    }
    /*
      subciptions
    */
    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );

    this.addSubscriptionToList(
      this.userService.getUserSubject.subscribe((user: UserInfo) => {
        this.initTables(user);
      })
    );

    this.addSubscriptionToList(
      this.getTaotlusedSubject.subscribe(taotlused => {
        this.getActiveApls(
          this.applicantId,
          this.aplPagConf.currentPage - 1,
          this.aplPagConf.itemsPerPage,
          this.aplSearch.sortBy,
          this.aplSearch.sortDir
        );
      })
    );
    /*
      ListItem From Session
    */
    if (this.clListItemMapByCode === undefined || this.clListItemMapByCode === null) {
      this.clListItemMapByCode = this.classifierService.getListItemMapByCodeFromSession();
    }
    this.userInfo = userInfo;
    this.getUnpayedFinFee();
    this.addSubscriptionToList(
      this.userService.getNewRepresentativeEvent().subscribe(() => { // ON ROLE CHANGE REFRESH FRONT PAGE STUFF
        this.getUnpayedFinFee();
      }));
  }

  initSpinner() {
    if (this.loadingServicesCount === 0) {
      this.addSpinnerLayer();
    }
    this.loadingServicesCount += 1;
  }

  cancelSpinner() {
    this.loadingServicesCount -= 1;
    if (this.loadingServicesCount === 0) {
      this.removeSpinnerLayer();
    }
  }

  getUnpayedFinFee() {
    // this.initSpinner();
    this.unpaidFeesLoading = true;
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    this.addSubscriptionToList(
      this.obligationService.getUnpayedFinFee(
        this.unpayedFinFeesPageConf.currentPage - 1,
        this.unpayedFinFeesPageConf.itemsPerPage,
        this.finfeeSearch.sortBy,
        this.finfeeSearch.sortDir).subscribe(
          response => {
            if (response.success) {
              this.unpayedFinFees = response.data as Statefee[];
              this.unpayedFinFeesPageConf.totalItems = response.total;
            } else {
              this.showDbErrorMessage(defaultErrorMsgLabel, response);
            }
            // this.cancelSpinner();
            this.unpaidFeesLoading = false;
          },
          error => {
            this.showDbErrorMessage(defaultErrorMsgLabel, error);
            // this.cancelSpinner();
            this.unpaidFeesLoading = false;
          }
        )
    );
  }

  getUnpayedFinFeePage() {
    this.finfeeSearch.page = this.unpayedFinFeesPageConf.currentPage - 1;
    this.finfeeSearch.size = this.unpayedFinFeesPageConf.itemsPerPage;
    this.myUnpayedFinFeePaginationData.page = this.unpayedFinFeesPageConf.currentPage - 1;
    this.myUnpayedFinFeePaginationData.size = this.unpayedFinFeesPageConf.itemsPerPage;
    this.saveTableParamsToSession('unpayedFinFeesTableId', this.unpayedFinFeesPageConf);
    this.getUnpayedFinFee();
  }

  onUnpayedFinfeeSorted(event: any) {
    this.finfeeSearch.sortBy = event.sortColumn;
    this.finfeeSearch.sortDir = event.sortDirection;
    this.finfeeSearch.page = 0;
    this.unpayedFinFeesPageConf.currentPage = 1;
    this.saveTableParamsToSession('insAuditSearchResTable', this.unpayedFinFeesPageConf);
    this.getUnpayedFinFee();
  }

  ngOnDestroy(): void {
    this.unsubscribeAllFromList();
  }

  initTables(user: UserInfo) {
    if (user !== undefined) {
      if (user.entities[user.activeRoleEntity]) {
        this.applicantId = user.entities[user.activeRoleEntity].id;
        this.getActiveApls(
          this.applicantId,
          this.aplPagConf.currentPage - 1,
          this.aplPagConf.itemsPerPage,
          'compSubDate',
          'desc'
        );
      }
      this.getShips();
    }

    const activeRoleKey = user.activeRoleEntity;
    this.activeRoleEntity = user.entities[activeRoleKey];
    this.isLaevRiigihalduseksVisible =
      this.activeRoleEntity &&
      this.activeRoleEntity.personType === 'J' &&
      +user.countryId === 250 &&
      this.activeRoleEntity.code !== null &&
      this.activeRoleEntity.code.charAt(0) === '7';
  }

  regNew(taotluseLiik: number) {
    this.addSubscriptionToList(
      this.shipService.createDraftIk(taotluseLiik).subscribe(
        (res: any) => {
          const defaultErrorMsgLabel = 'ERROR.CREATE_DATA';
          if (res.success === true) {
            this.currentTaotlus = res.data as Apl;
            // show msg
            this.addSubscriptionToList(this.translateServ.get('DS.TAOTLUS_SUCCESS').subscribe(label => {
              this.getLongMsgSuccess(label + ' (nr: ' + this.currentTaotlus.registrationNumber + ')');
            }));
            this.getTaotlusedSubject.next();

            this.goToPage(this.lang + '/' + this.currentEnv + '/taotlused/taotlus/' + this.currentTaotlus.id);
          } else {
            this.showDbErrorMessage(defaultErrorMsgLabel, res);
          }
          // sulge modaal
          this.closeModal();
        },
        error => {
          this.closeModal();
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  goToPage(path: string) {
    this.router.navigate([path]);
  }

  openModal(template: TemplateRef<any>, taotluseLiik: number) {
    this.currentTaotluseLiik = taotluseLiik;
    /*
      Close popover
    */
    const popover = document.querySelector('popover-container');
    if (popover !== null) {
      popover.classList.add('d-none');
    }

    // add correct template
    switch (this.currentTaotluseLiik) {
      case 21004:
        this.dialogContentTemplate = this.confirmToimingContent21004Tpl;
        break;
      case 21005:
        this.dialogContentTemplate = this.confirmToimingContent21005Tpl;
        break;

      default:
        this.dialogContentTemplate = this.confirmToimingContentOtherTpl;
        break;
    }
    /*
    if (this.currentTaotluseLiik === 21004) {
      this.dialogContentTemplate = this.confirmToimingContent21004Tpl;
    } else {
      this.dialogContentTemplate = this.confirmToimingContentOtherTpl;
    } */
    // get USER
    this.currentUser = this.userService.getUser();

    this.currentTaotluseNimi = undefined;
    this.getTaotluseNimi();
    // open modal
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg' }));
  }

  getTaotluseNimi() {
    if (this.currentTaotluseNimi === undefined) {
      switch (this.currentTaotluseLiik) {
        case 21001: {
          this.addSubscriptionToList(this.translateServ.get('DS.LBL3').subscribe(value => {
            this.currentTaotluseNimi = value;
          }));
          break;
        }
        case 21002: {
          this.addSubscriptionToList(this.translateServ.get('DS.LBL4').subscribe(value => {
            this.currentTaotluseNimi = value;
          }));
          break;
        }
        case 21003: {
          this.addSubscriptionToList(this.translateServ.get('DS.LBL5').subscribe(value => {
            this.currentTaotluseNimi = value;
          }));
          break;
        }
        case 21004: {
          this.addSubscriptionToList(this.translateServ.get('DS.LBL6').subscribe(value => {
            this.currentTaotluseNimi = value;
          }));
          break;
        }
      }
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  /*
    Taotlused table
  */
  // LAB backend tegelikult ei arvesta esimest argumenti, päring toimub aktiivse kasutaja id järgi
  getActiveApls(applicantId: number, page: number, size: number, sortedBy: string, sortedDir: string) {
    // this.initSpinner();
    this.notFinishedAplLoading = true;

    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    this.addSubscriptionToList(
      this.aplService.searchActiveAplByApplicantId(applicantId, page, size, sortedBy, sortedDir).subscribe(
        response => {
          if (response.success) {
            if (response.data !== undefined && response.data !== null) {
              this.aplSearchResults = response.data as Apl[];
            } else {
              this.aplSearchResults = [];
            }
            this.aplPagConf.totalItems = response.total;
          } else {
            this.showDbErrorMessage(defaultErrorMsgLabel, response);
          }
          // this.cancelSpinner();
          this.notFinishedAplLoading = false;
        },
        error => {
          this.showDbErrorMessage(defaultErrorMsgLabel, error);
          // this.cancelSpinner();
          this.notFinishedAplLoading = false;
        }
      )
    );
  }

  onSorted(event: any) {
    this.aplSearch.sortBy = event.sortColumn;
    this.aplSearch.sortDir = event.sortDirection;
    this.aplSearch.size = this.aplPagConf.itemsPerPage;

    this.getActiveApls(
      this.applicantId,
      this.aplPagConf.currentPage - 1,
      this.aplPagConf.itemsPerPage,
      this.aplSearch.sortBy,
      this.aplSearch.sortDir
    );
  }

  getAplPage() {
    this.aplSearch.page = this.aplPagConf.currentPage - 1;
    this.aplSearch.size = this.aplPagConf.itemsPerPage;
    this.myAplTablePaginationData.page = this.aplPagConf.currentPage - 1;
    this.myAplTablePaginationData.size = this.aplPagConf.itemsPerPage;
    this.saveTableParamsToSession('aplTableId', this.aplPagConf);
    this.getActiveApls(
      this.applicantId,
      this.aplPagConf.currentPage - 1,
      this.aplPagConf.itemsPerPage,
      this.aplSearch.sortBy,
      this.aplSearch.sortDir
    );
  }

  /*
    Ship table
  */
  getShips() {
    // this.initSpinner();
    this.shipsLoading = true;
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    this.addSubscriptionToList(
      this.shipService
        .getShipWithOwner(this.shipPagConf.currentPage - 1, this.shipPagConf.itemsPerPage)
        .subscribe(
          (res: any) => {
            if (res.success === true) {
              this.shipSearchResults = res.data as Ship[];
              this.shipSearchResults = this.shipSearchResults.map((item: Ship) => {
                item.registryCl = this.clListItemMapByCode[item.registry];
                item.classTypeCl = this.clListItemMapByCode[item.classType];
                item.categoryCl = item.category ? this.clListItemMapByCode[item.category] : '';
                item.statusCl = this.clListItemMapByCode[item.status];
                return item;
              });
              this.shipPagConf.totalItems = res.total;
            } else {
              this.showDbErrorMessage(defaultErrorMsgLabel, res);
            }
            // this.cancelSpinner();
            this.shipsLoading = false;
          },
          error => {
            this.showDbErrorMessage(defaultErrorMsgLabel, error);
            // this.cancelSpinner();
            this.shipsLoading = false;
          }
        )
    );
  }

  getShipPage() {
    this.saveTableParamsToSession('shipTableId', this.shipPagConf);
    this.myShipTablePaginationData.page = this.shipPagConf.currentPage - 1;
    this.myShipTablePaginationData.size = this.shipPagConf.itemsPerPage;
    this.getShips();
  }


  /*
   TABLE session
  */
  saveTableParamsToSession(tableSessionName, pagConf) {
    this.tableSessionService.saveTableSessionParams(tableSessionName, null, null, pagConf);
  }

  clearTableParamsToSession(tableSessionName) {
    this.tableSessionService.clearTableSessionFor(tableSessionName);
  }


  initTableParamsFromSession(tableSessionName) {
    const params = this.tableSessionService.getTableSessionParams(tableSessionName);
    /* if (params !== null && params !== undefined) {
      if (params.pageConf !== undefined) {
        pagConf = params.pageConf;
      }
    } */
    return params;
  }

}
