import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// import { TranslateService } from '@ngx-translate/core';

declare var $: any; // jQuery gets imported at angular.json

@Injectable({
  providedIn: 'root'
})
export class UiHelper {
  constructor(private sanitized: DomSanitizer) {}

  /**
   * Scrolls to a specified anchor with a delay and an animation
   */
  scrollToAnchor(selector) {
    setTimeout(() => {
      const aTag = $('' + selector + '');
      if (aTag.length > 0) {
        $('html,body').animate({ scrollTop: aTag.offset().top }, 'slow');
      }
    }, 150);
  }

  sanitizeStyle(value) {
    if (value && value != null) {
      return this.sanitized.bypassSecurityTrustStyle(value);
    } else {
      return '';
    }
  }

  /*
   * Does what the title says...
   */
  openExternalLink(url: string): void {
    window.open(url, '_blank');
  }

  /* initDraggableDialog() {
    $(() => {
      $('.modal-dialog').draggable();
    });
  } */

  /**
   * TODO: find a better way to always init that lazy-ass jquery selectpicker
   */
  initSelectPicker(): void {
    // console.log('DEBUG: initSelectPicker called!');
    $(() => {
      $('.selectpicker').selectpicker();
    });
  }

  deselectAll(): void {
    $(() => {
      $('.selectpicker').selectpicker('deselectAll');
    });
  }

  refreshSelectPicker(): void {
    $(() => {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  renderSelectPicker(): void {
    $(() => {
      $('.selectpicker').selectpicker('render');
    });
  }

  initSelectPickerById(id: string): void {
    $(() => {
      $(id).selectpicker();
    });
  }

  refreshSelectPickerById(id: string): void {
    $(() => {
      $(id).selectpicker('refresh');
    });
  }

  setSelectValue(id: string, myValue: any) {
    $(() => {
      $(id).val(myValue);
    });
  }

  getSelectPickerById(id: string): any {
    return $(id);
  }

  translateSelectPicker(id: string): void {
    $(() => {
      // DOTO: testimine
      /*  const sP = $('.selectpicker').selectpicker('refresh');
       sP.DEFAULTS.selectAllText = 'bla'; */
      const sP = $('.selectpicker').selectpicker('val');
    });
  }
  computedClasses = [];
  allowedProperties = [
    'font-size',
    'color',
    'font-weight',
    'padding',
    'margin',
    'font-family',
    'background-color',
    'border-collapse',
    'margin-bottom',
    'margin-top',
    'padding-bottom',
    'padding-top',
    'text-transform',
    'border-top',
    'border-bottom',
    'border',
    'border-left',
    'border-right',
    'text-align'
  ];
  exportToHtml(selector, name) {
    this.computedClasses = [];
    let config = {
      recursive: true,
      properties: this.allowedProperties
    };

    this.computedStyleToInlineStyle(document.querySelector(selector), config);
    let htmlObject = this.getParsedContent(selector);
    this.clearInlineStyle(document.querySelector(selector));
    return htmlObject;
  }
  /** Adds computed style to the element. */
  private computedStyleToInlineStyle(element, options: any = {}) {
    if (!element) {
      throw new Error('No element specified.');
    }

    if (options.recursive) {
      for (let child of element.children) {
        this.computedStyleToInlineStyle(child, options);
      }
    }

    const computedStyle = getComputedStyle(element);
    let computedClass: any = {};
    computedClass.className = 'generated-' + Date.now() + '-' + Math.floor(Math.random() * 1000);
    computedClass.cssContent = '';
    (options.properties || computedStyle).forEach(property => {
      let propertyValue = computedStyle.getPropertyValue(property);
      if (property === 'margin') {
        propertyValue = '0px';
      }
      if (property === 'padding') {
        propertyValue = '0px 8px';
      }
      // needed for pdf generation in the back
      if (property === 'font-family') {
        propertyValue = "'Roboto', sans-serif";
      }
      computedClass.cssContent += property + ': ' + propertyValue + '; ';
    });

    if (element.tagName.toLowerCase() == 'table') {
      computedClass.cssContent += 'width: 100%;';
      computedClass.cssContent += 'border-collapse: collapse;';
    }

    if (element.tagName.toLowerCase() == 'td') {
      computedClass.cssContent += 'padding: 3px;';
      computedClass.cssContent += 'border: 1px solid rgb(221, 221, 221);';
      computedClass.cssContent += 'border-collapse: collapse;';
      computedClass.cssContent += 'width: 100%;';
    }

    if (element.tagName.toLowerCase() == 'th') {
      computedClass.cssContent += 'padding: 4px;';
      computedClass.cssContent += 'border: 1px solid rgb(221, 221, 221);';
      computedClass.cssContent += 'border-collapse: collapse;';
      computedClass.cssContent += 'display: table-cell;';
      computedClass.cssContent += 'width: 2%;';
    }

    if (element.tagName.toLowerCase() == 'textarea') {
      computedClass.cssContent += 'width: 100%;';
      computedClass.cssContent += 'height: 200px;';
    }
    if (element.tagName.toLowerCase() == 'input' && element.type !== 'radio' && element.type !== 'checkbox') {
      computedClass.cssContent += 'width: 50%;';
    }
    if (element.tagName.toLowerCase() == 'button' && element.classList.contains('dropdown-toggle')) {
      computedClass.cssContent += 'width: 50%;';
    }
    this.computedClasses.push(computedClass);
    element.classList.add(computedClass.className);
  }
  /** Removes style attribute from element and its children  */
  private clearInlineStyle(element) {
    for (let child of element.children) {
      this.clearInlineStyle(child);
    }
    this.computedClasses.forEach(c => {
      element.classList.remove(c.className);
    });
  }
  private getComputedClassesStylesheet() {
    let ssh = '';
    this.computedClasses.forEach(c => (ssh += '.' + c.className + ' { ' + c.cssContent + ' } '));
    return ssh;
  }
  private getParsedContent(selector) {
    let htmlObject = document.createElement('html');
    let els = document.querySelector(selector);
    els.querySelectorAll('input').forEach((el: HTMLInputElement) => {
      if (el.checked) {
        el.setAttribute('checked', 'checked');
      }
      if (el.value) {
        el.setAttribute('value', el.value);
      }
    });

    htmlObject.innerHTML =
      '<head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"/><style>' +
      this.getComputedClassesStylesheet() +
      '</style></head><body>' +
      els.innerHTML +
      '</body>';
    htmlObject.querySelectorAll('.dropdown-menu').forEach(el => el.remove());
    htmlObject.querySelectorAll('.selectpicker').forEach(el => el.remove());
    htmlObject.querySelectorAll('.sort-arrows').forEach(el => el.remove());
    htmlObject.querySelectorAll('.btn-square').forEach(el => el.remove());
    htmlObject.querySelectorAll('.btn-with-plus').forEach(el => el.remove());
    htmlObject.querySelectorAll('.btn-danger').forEach(el => el.remove());
    htmlObject.querySelectorAll('.btn-info').forEach(el => el.remove());
    htmlObject.querySelectorAll('.alert-warning').forEach(el => el.remove());

    // replace line breaks with <br>
    htmlObject.innerHTML = htmlObject.innerHTML.replace(/(?:\r\n|\r|\n)/g, '<br>');

    return htmlObject;
  }
}
