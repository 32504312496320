import { ValidatorFn, AbstractControl, FormGroup, FormArray } from '@angular/forms';

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

// LAB validator for dates from datepicker
export function isDateValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value) {
      try {
        if (control.value.toString() === 'Invalid Date') {
          return { invalidDateFormat: true };
        }
      } catch {
        return { invalidDateFormat: true };
      }
    }
    return null;
  };
}

export function dateLessThanOrEqualToDoday(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null; // Don't validate empty values to allow optional controls
    }
    const currentDate = new Date();
    const currentUtcDate = new Date(
      Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
    );
    const inputDate = new Date(control.value);
    const inputUtcDate = new Date(
      Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate())
    );

    return inputUtcDate <= currentUtcDate ? null : { invalidDateFuture: true };
  };
}
// LAB date is in future and no later than endDate
export function maxFutureDateValidator(endDate: Date): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value) {
      try {
        const inputDate = new Date(control.value.getTime());
        const currentDate = new Date();
        if (inputDate.setHours(0, 0, 0) <= currentDate.setHours(0, 0, 0)) {
          return { invalidDatePast: true };
        }
        if (endDate.setHours(0, 0, 0) < inputDate.setHours(0, 0, 0)) {
          return { invalidDateFuture: true };
        }
      } catch {
        return { invalidDateRange: true };
      }
    }
    return null;
  };
}

export function isNullOrUndefined(val: string): boolean {
  return val === null || val === undefined;
}

export function dateStartEndValidation(dateFields: { start: string; end: string }[]) {
  return (group: FormGroup): void => {
    dateFields.forEach(item => {
      const start = group.controls[item.start];
      const end = group.controls[item.end];
      const startDate = start.value && new Date(start.value);
      const endDate = end.value && new Date(end.value);
      if (startDate && endDate && startDate > endDate) {
        end.setErrors({ startDate: true });
      } else if (end.getError('startDate')) {
        delete end.errors.startDate;
        end.updateValueAndValidity();
      }
    });
  };
}

export function dateIssuedStartEndValidation(dateFields: { issued: string; start: string; end: string }[]) {
  return (group: FormGroup): void => {
    dateFields.forEach(item => {
      const issued = group.controls[item.issued];
      const start = group.controls[item.start];
      const end = group.controls[item.end];
      const issuedDate = issued.value && new Date(issued.value);
      const startDate = start.value && new Date(start.value);
      const endDate = end.value && new Date(end.value);
      if (startDate && issued && startDate < issuedDate) {
        start.setErrors({ issueDate: true });
      } else if (startDate && endDate && startDate > endDate) {
        end.setErrors({ startDate: true });
      } else if (start.getError('issueDate')) {
        delete start.errors.issueDate;
        start.updateValueAndValidity();
      } else if (end.getError('startDate')) {
        delete end.errors.startDate;
        end.updateValueAndValidity();
      }
    });
  };
}

export function dateKeelLayingBuildingValidation(appType) {
  return (group: FormGroup): void => {
    const keelLayingDate = group.controls['appTechnicalsById']?.get('keelLayingDate');
    const keelLayingYear = keelLayingDate.value && new Date(keelLayingDate.value)?.getFullYear();
    if (appType === 21002 && !keelLayingYear) {
      keelLayingDate.setErrors({ required: true });
    }
    const buildings = group.controls['appBuildingsById'] as FormArray;

    if (!buildings) {
      return null;
    }

    let previousControlYear = keelLayingYear;

    for (let i = 0; i < buildings.length; i++) {
      const building = buildings.at(i);
      const buildingYearControl = building.get('buildingYear');
      const buildingYear = Number(buildingYearControl?.value);
      if (i === 0) {
        previousControlYear = buildingYear;
      }
      if (i === 0 && buildingYear < keelLayingYear) {
        buildingYearControl.setErrors({ keelYear: true });
      } else if (i !== 0 && buildingYear < previousControlYear) {
        buildingYearControl.setErrors({ buildingYear: true });
      } else if (buildingYearControl.getError('buildingYear')) {
        delete buildingYearControl.errors.buildingYear;
        buildingYearControl.updateValueAndValidity();
      } else if (buildingYearControl.getError('keelYear')) {
        delete buildingYearControl.errors.keelYear;
        buildingYearControl.updateValueAndValidity();
      } // Update the previous date to the current for the next iteration
    }
  };
}

export function convertToNumber(number?: any): number {
  if (typeof number === 'string' && number.includes(',')) {
    const parts = number.split(',', 2);
    return +parts[0] + +parts[1] / 10 ** parts[1].length;
  }
  return +number;
}

export function numberStartEndValidation(numberFields: { start: string; end: string }[]) {
  return (group: FormGroup): void => {
    numberFields.forEach(item => {
      const start = group.controls[item.start];
      const end = group.controls[item.end];
      const startValue = convertToNumber(start.value);
      const endValue = convertToNumber(end.value);
      if (startValue && endValue && startValue > endValue) {
        end.setErrors({ startNumber: true });
      } else if (end.getError('startNumber')) {
        delete end.errors.startNumber;
        end.updateValueAndValidity();
      }
    });
  };
}
