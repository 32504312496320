import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_ENDPOINT } from '../../app.constants';
import { ClassificatorObjectElement } from 'src/app/_domain';
import { LangswitchService } from './langswitch.service';
import { ListTypes } from '../../inspection-requirements/inspection-requirements.model';
import {
  ClaBuilderForm,
  ClaIceClassForm,
  ClaShipFlagLawForm,
  ClaSocietyForm,
  ClaUsageTypeForm,
  ListItemForm
} from 'src/app/classifiers/classifiers.model';

@Injectable({
  providedIn: 'root'
})
export class ClassifierService {
  listitemsSessionKey = 'classifier-listitems';
  usageTypeSessionKey = 'classifier-usage-type';
  listitemsMapSessionKey = 'classifier-listitems-map-by-list-type';
  classifierByCodeMapSessionKey = 'classifier-map-by-code';
  regPortSessionKey = 'classifier-regport';
  countrySessionKey = 'classifier-country';
  iceClassSessionKey = 'classifier-iceclass';
  designCategorySessionKey = 'classifier-design-category';
  rolePrivilegesSessionKey = 'classifier-role-privileges';
  societySessionKey = 'classifier-society';
  inspectionSessionKey = 'classifier-inspection-subtypes';
  userRoleSessionKey = 'classifier-role';

  mapByCode: Map<string, ClassificatorObjectElement> = {} as Map<string, ClassificatorObjectElement>;

  constructor(private http: HttpClient, private langswitchService: LangswitchService) {}

  getClassifierByListType(listType: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/classifier/listitem/' + listType);
  }
  getClassifierFullByListType(listType: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/listitem/' + listType);
  }
  getClassifierFullByCode(code: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/listitem/item/' + code);
  }
  getClassifierByListTypes(listTypes: IterableIterator<number>): Observable<ListTypes> {
    const params = new HttpParams({ fromObject: { listTypes: [...listTypes].join(',') } });
    return this.http.get<ListTypes>(API_ENDPOINT + 'api/classifier/listitems', { params });
  }

  getClassifiers() {
    const listitems = JSON.parse(sessionStorage.getItem(this.listitemsSessionKey));
    const mapByListTypeS = sessionStorage.getItem(this.listitemsMapSessionKey);
    const mapByCodeS = sessionStorage.getItem(this.classifierByCodeMapSessionKey);
    if (
      listitems !== undefined &&
      listitems !== null &&
      mapByListTypeS !== undefined &&
      mapByListTypeS !== null &&
      mapByCodeS !== undefined &&
      mapByCodeS !== null
    ) {
      return new Observable(observer => {
        observer.next(listitems);
      });
    }
    return this.http.get<any>(API_ENDPOINT + 'api/classifier/listitem').pipe(
      map((response: any) => {
        sessionStorage.setItem(this.listitemsSessionKey, JSON.stringify(response));
        // listitem by list type
        if (mapByListTypeS === undefined || mapByListTypeS === null) {
          // array to map:  key = listType
          const mapByListType = this._listTypeToMap(response);
          sessionStorage.setItem(this.listitemsMapSessionKey, JSON.stringify(mapByListType));
        }

        // listitem by code
        if (mapByCodeS === undefined || mapByCodeS === null) {
          this.mapByCode = {} as Map<string, ClassificatorObjectElement>;
          // array to map:  key = code
          response.forEach(element => {
            this.mapByCode[element.code] = element;
          });
          sessionStorage.setItem(this.classifierByCodeMapSessionKey, JSON.stringify(this.mapByCode));
        }

        return response;
      })
    );
  }

  getRegPort() {
    return this._cachedClassifier(this.regPortSessionKey, 'api/classifier/regport');
  }

  getCountry() {
    return this._cachedClassifier(this.countrySessionKey, 'api/classifier/country');
  }

  getInspectionSubtypes() {
    return this._cachedClassifier(this.inspectionSessionKey, 'api/classifier/inspection_subtype');
  }

  getInspectionSybTypesByTypeCode(typeCode: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/classifier/inspection-subtype/' + typeCode);
  }

  getValidPortList() {
    return this.http.get<any>(API_ENDPOINT + 'api/classifier/port-list');
  }

  getDesignCategory() {
    return this._cachedClassifier(this.designCategorySessionKey, 'api/classifier/DESIGN_CATEGORY');
  }

  getRolePrivileges() {
    return this._cachedClassifier(this.rolePrivilegesSessionKey, 'api/classifier/ROLE_PRIVILEGE');
  }

  getIceClass() {
    return this._cachedClassifier(this.iceClassSessionKey, 'api/classifier/iceclass');
  }

  getSociety() {
    return this._cachedClassifier(this.societySessionKey, 'api/classifier/society');
  }

  getUsage() {
    return this._cachedClassifier(this.usageTypeSessionKey, 'api/classifier/usage_type');
  }

  getRoles() {
    return this._cachedClassifier(this.userRoleSessionKey, 'api/classifier/role');
  }

  getBuilderById(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/builder/' + id);
  }

  private _cachedClassifier(key: string, url: string) {
    const values = JSON.parse(sessionStorage.getItem(key));
    if (values !== undefined && values !== null) {
      return new Observable(observer => {
        observer.next(values);
      });
    }
    return this.http.get<any>(API_ENDPOINT + url).pipe(
      map((response: any) => {
        sessionStorage.setItem(key, JSON.stringify(response));
        return response;
      })
    );
  }

  private _listTypeToMap(data) {
    return data.reduce((oMap: any, obj: any) => {
      if (oMap[obj.listType] === undefined) {
        oMap[obj.listType] = [obj];
      } else {
        oMap[obj.listType].push(obj);
      }
      return oMap;
    }, {});
  }

  /*
    FROM SESSION
  */

  // By CODE
  getListItemMapByCodeFromSession(): any {
    return JSON.parse(sessionStorage.getItem(this.classifierByCodeMapSessionKey));
  }
  // By TYPE
  getListItemMapByListTypeFromSession(): any {
    const lang = this.langswitchService.getCurrentLang();
    const key = this.listitemsMapSessionKey + '-' + lang;
    let data = JSON.parse(sessionStorage.getItem(key));
    if (data === undefined || data === null) {
      const list = this.getListItemResponseFromSession();
      list.sort((a, b) => {
        const order = (a.sortorder || 0) - (b.sortorder || 0);
        return order || a.descr[lang].localeCompare(b.descr[lang]);
      });
      data = this._listTypeToMap(list);
      sessionStorage.setItem(key, JSON.stringify(data));
    }
    return data;
  }
  // RAW
  getListItemResponseFromSession(): any {
    return JSON.parse(sessionStorage.getItem(this.listitemsSessionKey));
  }

  updateClaListItem(data: ListItemForm): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/listitem/item/`, data);
  }

  createClaListItem(data: ListItemForm, typeCode: string): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/manage/classifier/listitem/item/${typeCode}`, data);
  }

  invalidateClaListItem(typeCode: string): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/listitem/item/invalidate/${typeCode}`, {});
  }

  getClaBuilders(): Observable<any> {
    return this.http.get<any>(API_ENDPOINT + 'api/classifier/builder');
  }

  updateClaBuilder(data: ClaBuilderForm, id: number): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/builder/${id}`, data);
  }

  createClaBuilder(data: ClaBuilderForm): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/manage/classifier/builder`, data);
  }

  invalidateClaBuilder(id: string): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/builder/invalidate/${id}`, {});
  }

  getGroupedIceClasses() {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/iceclassgroups');
  }

  getIceClassById(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/iceclass/' + id);
  }

  updateIceClass(data: ClaIceClassForm): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/iceclass`, data);
  }

  createIceClass(data: ClaIceClassForm): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/manage/classifier/iceclass`, data);
  }

  invalidateClaIceClass(id: string): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/iceclass/invalidate/${id}`, {});
  }

  getClaSocieties() {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/society');
  }

  getClaSocietyById(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/society/' + id);
  }

  updateClaSociety(data: ClaSocietyForm): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/society`, data);
  }

  createClaSociety(data: ClaSocietyForm): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/manage/classifier/society`, data);
  }

  invalidateClaSociety(id: string): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/society/invalidate/${id}`, {});
  }

  getClaShipFlagLaws() {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/shipflaglaw');
  }

  getClaShipFlagLawById(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/shipflaglaw/' + id);
  }

  updateClaShipFlagLaw(data: ClaShipFlagLawForm): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/shipflaglaw`, data);
  }

  createClaShipFlagLaw(data: ClaShipFlagLawForm): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/manage/classifier/shipflaglaw`, data);
  }

  invalidateClaShipFlagLaw(id: string): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/shipflaglaw/invalidate/${id}`, {});
  }

  getClaClaUsageTypes() {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/usagetype');
  }

  getClaUsageTypeById(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/manage/classifier/usagetype/' + id);
  }

  updateClaUsageType(data: ClaUsageTypeForm): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/usagetype`, data);
  }

  createClaUsageType(data: ClaUsageTypeForm): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/manage/classifier/usagetype`, data);
  }

  invalidateClaCUsageType(id: string): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/manage/classifier/usagetype/invalidate/${id}`, {});
  }
}
