<app-loading-spinner *ngIf="isLoading"  [isBig]="true"></app-loading-spinner>
<!-- HEADER -->
<div *ngIf="!isLoading" class="formHeader d-flex justify-content-start align-items-stretch align-content-stretch position-relative bg-beacon">
  <div class="formHeaderRight d-flex flex-column flex-grow-1 ml-5 my-5 mr-auto ">
    <h1 class="my-0">{{ 'AUTH.TITLE' | translate }}</h1>
    <div *ngIf="volitusId && volitusStatus" class="applicationState d-inline-block my-2">
      <span>
        {{ 'AUTH.STATUS.' + volitusStatus | translate }}
      </span>
    </div>
    <!-- <div *ngIf="!volitusStatus"  class="px-4">
      <app-loading-spinner></app-loading-spinner>
    </div> -->
  </div>
  <div class="right-0 top-0 bottom-0 mt-5 mr-5">
    <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}" (click)="goBack()"></button>
  </div>
</div>
<!-- BODY -->
<div [formGroup]="appVolitusForm" class="row px-5 mt-5">
  <div class="col-20 verticalStepForm mb-5" *ngIf="!isLoading">
    <!---
       VOLITAJA
    --->
    <div id="volitajaSectionId">
      <div class="section-title pr-0">
        <a class="section-collapse" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1">{{'AUTH.VOLITAJA.TITLE' | translate}}</a>
        <div class="title-line mb-0"></div>
      </div>
      <div [collapse]="isCollapsed1" id="volitajaId" class="position-relative z-0 mb-0 pb-4">
        <div>
          <app-loading-spinner *ngIf="isVolitajaLoading"  [isBig]="true"></app-loading-spinner>
        </div>
        <div *ngIf="!isVolitajaLoading"  class="text-right mb-0 d-block">
          <div *ngIf="mode === 'rw'">
            <button *ngIf="env === 'mk' && (
              !hasVolitaja || (this.currentVolitajaFormData.personId !== null &&
              this.currentVolitajaFormData.personId !== undefined &&
              this.currentVolitatudFormData.personId !== undefined &&
              this.currentVolitatudFormData.personId !== null  &&
              this.currentVolitajaFormData.personId === this.currentVolitatudFormData.personId))
              " id="addOwnerBtnId" type="button" info [style]="'square'" [icon]="'with-plus'" (click)="openCreateOwnershipModal(null, null, volitaja)">
              <span>{{'AUTH.VOLITAJA.ADD' | translate}}</span>
            </button>
            <!-- ERRORS -->
            <div class="mt-1 invalid-feedback d-block">
              <div class="invalid-feedback" *ngIf="(this.currentVolitajaFormData.personId !== null && this.currentVolitajaFormData.personId !== undefined &&
                this.currentVolitatudFormData.personId !== undefined && this.currentVolitatudFormData.personId !== null &&
                this.currentVolitajaFormData.personId === this.currentVolitatudFormData.personId)">
                {{'AUTH.PERSON_ARE_SAME_ERROR' | translate }}
              </div>
              <!--   <div class="invalid-feedback mt-1"
                *ngIf="hasVolitaja && this.currentVolitajaFormData.personId !== null && allVolitajaVessels?.length === 0">
                {{'AUTH.VOLITAJA_DO_NOT_HAVE_VESSELS_ERROR' | translate }}
              </div> -->
            </div>
          </div>
          <div *ngIf="hasVolitaja" class="form-group row mx-0 z-0 mb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-form-label text-right px-0">
                  {{'APL.REQ_OWN_COUNTRY' | translate}}: </label>
                <div class="col-xs-20 col-md-15 col-sm-18 pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p *ngIf="countryMap.size > 0" class=" mb-0">
                      {{countryMap.get(currentVolitajaFormData?.personCountryId)}}
                    </p>
                    <app-loading-spinner *ngIf="countryMap.size === 0"></app-loading-spinner>
                  </div>
                </div>
                <label class="col-xs-20 col-md-5 col-form-label text-right px-0">
                  {{(currentVolitajaFormData?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_ICODE' : 'APL.REQ_OWN_RCODE') | translate}}: </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">{{currentVolitajaFormData?.personRegCode}}</p>
                  </div>
                </div>
                <div *ngIf="currentVolitajaFormData?.personType === PersonTypeEnum.F" class="row mx-0 col-20 px-0 mb-0">
                  <label class="col-xs-20 col-md-5 col-form-label text-right px-0">
                    {{'APL.REQ_OWN_FNAME' | translate}}: </label>
                  <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                    <div class="d-flex h-100 mb-0 align-items-center">
                      <p class=" mb-0">{{currentVolitajaFormData?.personFirstName}}</p>
                    </div>
                  </div>
                </div>
                <label class="col-xs-20 col-md-5 col-form-label text-right px-0">
                  {{(currentVolitajaFormData?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_LNAME' : 'APL.REQ_OWN_NAME') | translate}}: </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <a (click)="openModal2(currentVolitajaFormData)">{{currentVolitajaFormData?.personName}}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row col-20">
              <label class="col-xs-20 col-md-5 col-form-label text-right px-0">
                {{'APL.REQ_OWN_ADDRESS' | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p class=" mb-0">{{currentVolitajaFormData?.address}}</p>
                </div>
              </div>
            </div> -->
            <!-- <div class="row col-20">
              <label class="col-xs-20 col-md-5 col-form-label text-right px-0">
                {{'APL.REQ_OWN_EMAIL' | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p class=" mb-0">{{currentVolitajaFormData?.email}}</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div> <!-- /volitaja end-->
    <!---
       VOLITATUD
    --->
    <div id="volitatudSectionId">
      <div class="section-title pr-0">
        <a class="section-collapse" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2">{{'AUTH.VOLITATUD.TITLE' | translate}}</a>
        <div class="title-line mb-0"></div>
      </div>
      <div [collapse]="isCollapsed2" id="volitatudId" class="position-relative z-0 mb-0 pb-4">
        <div>
          <app-loading-spinner *ngIf="isVolitatudLoading"  [isBig]="true"></app-loading-spinner>
        </div>
        <div *ngIf="mode === 'rw' && !isVolitatudLoading" class="text-right mb-0 d-block">
          <!--  BUTTON  -->
          <button *ngIf="env === 'mk' && (!hasVolitatudIsik ||
              (this.currentVolitajaFormData.personId !== null && this.currentVolitajaFormData.personId !== undefined &&
            this.currentVolitatudFormData.personId !== undefined && this.currentVolitatudFormData.personId !== null &&
            this.currentVolitajaFormData.personId === this.currentVolitatudFormData.personId) ||
             this.currentVolitajaFormData.personId !== null && allVolitajaVessels?.length === 0)" id="addOwnerBtnId" type="button" info [style]="'square'" [icon]="'with-plus'" (click)="openCreateOwnershipModal(null, null, volitatud)">
            <span>{{'AUTH.VOLITATUD.ADD' | translate}}</span>
          </button>
          <button
            *ngIf="env === 'ik' && !hasVolitatudIsik"
            type="button"
            info
            id="addOwnerBtnId"
            [style]="'square'"
            [icon]="'with-plus'"
            (click)="openCreateOwnershipModal(null, null, volitatud)"
          >
            <span>{{'AUTH.VOLITATUD.ADD' | translate}}</span>
          </button>
          <div class="mt-1 invalid-feedback" *ngIf="(this.currentVolitajaFormData.personId !== null && this.currentVolitajaFormData.personId !== undefined &&
            this.currentVolitatudFormData.personId !== undefined && this.currentVolitatudFormData.personId !== null &&
            this.currentVolitajaFormData.personId === this.currentVolitatudFormData.personId)">
            {{'AUTH.PERSON_ARE_SAME_ERROR' | translate }}
          </div>
        </div>
        <div *ngIf="hasVolitatudIsik" class="form-group row mx-0 z-0 mb-0">
          <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
            <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
              <label class="col-xs-20 col-md-5 col-sm-2  col-form-label text-right px-0">
                {{'APL.REQ_OWN_COUNTRY' | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p *ngIf="countryMap.size > 0" class=" mb-0">
                    {{countryMap.get(currentVolitatudFormData?.personCountryId)}}
                  </p>
                  <app-loading-spinner *ngIf="countryMap.size === 0"></app-loading-spinner>
                </div>
              </div>
              <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                {{(currentVolitatudFormData?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_ICODE' : 'APL.REQ_OWN_RCODE') | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p class=" mb-0">{{currentVolitatudFormData?.personRegCode}}</p>
                </div>
              </div>
              <div *ngIf="currentVolitatudFormData?.personType === PersonTypeEnum.F" class="row mx-0 col-20 px-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                  {{'APL.REQ_OWN_FNAME' | translate}}: </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">{{currentVolitatudFormData?.personFirstName}}</p>
                  </div>
                </div>
              </div>
              <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                {{(currentVolitatudFormData?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_LNAME' : 'APL.REQ_OWN_NAME') | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <a (click)="openModal2(currentVolitatudFormData)">{{currentVolitatudFormData?.personName}}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row col-20">
            <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
              {{'APL.REQ_OWN_ADDRESS' | translate}}: </label>
            <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
              <div class="d-flex h-100 mb-0 align-items-center">
                <p class=" mb-0">{{currentVolitatudFormData?.address}}</p>
              </div>
            </div>
          </div> -->
          <!-- <div class="row col-20">
            <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
              {{'APL.REQ_OWN_EMAIL' | translate}}: </label>
            <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
              <div class="d-flex h-100 mb-0 align-items-center">
                <p class=" mb-0">{{currentVolitatudFormData?.email}}</p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div><!-- volitatud end -->
    <!--
      Volituse kehtivus
    -->
    <div *ngIf="hasVolitaja" id="volituseKehtivusSectionId">
      <div class="section-title pr-0">
        <a class="section-collapse" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3">{{'AUTH.KEHTIVUS_TITLE' | translate}}</a>
        <div class="title-line mb-0"></div>
      </div>
      <div [collapse]="isCollapsed3" id="volituseKehtivusId" class="position-relative z-0 mb-0 pb-4">
        <div *ngIf="mode === 'ro'" class="form-group row mx-0 z-0 mb-0 pb-0">
          <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
            <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
              <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                {{'GLOBAL.KEHTIVUSE_ALGUS' | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p class=" mb-0">
                    {{currentVolitusData?.validFrom | date: localDateFormat}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /row -->
        <div class="form-group row mx-0 z-0 mb-0 pb-0">
          <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
            <div class="paddingLeftFix col-md-15 col-sm-20 row mx-0 pr-0 mb-0">
              <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                {{'GLOBAL.KEHTIVUSE_LOPP' | translate}}: </label>
              <div *ngIf="mode === 'ro'" class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p class=" mb-0">
                    {{currentVolitusData?.validTo ? (currentVolitusData.validTo | date: localDateFormat) : ('GLOBAL.LIMITLESS' | translate)}}
                  </p>
                </div>
                <div *ngIf="volitusSubmitted && appVolitusForm?.valid" class="invalid-feedback">
                  <div *ngIf="appVolitusForm.controls?.validTo?.errors?.required">
                    {{ 'ERROR.REQUIRED' | translate }}
                  </div>
                </div>
              </div>
              <div *ngIf="mode === 'rw'" class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-block h-100 mb-0 align-items-center ">
                  <div class="d-flex">
                    <app-datepicker [disableControl]="appVolitusForm.value.limitless" formControlName="validTo"><!-- [disabled]="appVolitusForm.value.limitless" -->
                    </app-datepicker>
                    <!-- [maxDate]="maxDate" -->
                    <div class="ml-5 mt-2 w-100 custom-control custom-checkbox">
                      <input id="tahtajatuInputId" type="checkbox" class="custom-control-input" [checked]="!appVolitusForm.value.validTo" formControlName="limitless" [value]="" (change)="clearKehtivusLopp()" />
                      <label class="custom-control-label" for="tahtajatuInputId">{{ 'GLOBAL.LIMITLESS' | translate }}</label>
                    </div>
                  </div>
                  <div *ngIf="volitusSubmitted && validToErrorMsg !== null" class="invalid-feedback">
                    <div>
                      {{ validToErrorMsg | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /row -->
        <div *ngIf="mode === 'ro' && currentVolitusData?.deletionReason" class="form-group row mx-0 z-0 mb-0 pb-0">
          <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
            <div class="col-17 row mx-0 pl-1 pr-0 mb-0">
              <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                {{'AUTH.TUHISTAMINE_TITLE' | translate}}: </label>
              <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                <div class="d-flex h-100 mb-0 align-items-center">
                  <p class=" mb-0">
                    {{currentVolitusData?.deletionReason}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /row -->
      </div> <!-- volituseKehtivusSectionId END -->
      <!--
          Volitatud õigused
      -->
      <div *ngIf="hasVolitaja" id="volituseOigusedSectionId">
        <div class="section-title pr-0">
          <a class="section-collapse" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="!isCollapsed4">{{'AUTH.OIGUSED_TITLE' | translate}}</a>
          <div class="title-line mb-0"></div>
        </div>
        <div [collapse]="isCollapsed4" id="volituseOigusedId" class="position-relative z-0 mb-0 pb-4">
          <!--
             Täisvolitus
          -->
          <div class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <!-- RW -->
                <div *ngIf="mode === 'rw'" class="custom-control custom-radio">
                  <input formControlName="unrestricted" id="unrestrictedTrueId" [checked]="appVolitusForm.value.unrestricted === 'true' || appVolitusForm.value.unrestricted === true" value="true" type="radio" class="custom-control-input"
                    (click)="setUnrestricted('true')">
                  <label class="custom-control-label" for="unrestrictedTrueId">{{ 'AUTH.TAISVOLITUS' |translate }}</label>
                </div>
                <!-- RO -->
                <div *ngIf="mode === 'ro' && currentVolitusData?.unrestricted" class="col-xs-20 col-md-15 col-sm-20  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">
                      {{ 'AUTH.TAISVOLITUS' |translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <!--
                Osaline volitus
            -->
          <!--
                RW
            -->
          <div *ngIf="mode === 'rw'" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div class="custom-control custom-radio">
                  <input formControlName="unrestricted" value="false" [checked]="appVolitusForm.value.unrestricted === 'false' || appVolitusForm.value.unrestricted === false" id="unrestrictedFalseId" value="false" type="radio"
                    class="custom-control-input" (click)="setUnrestricted('false')">
                  <label class="custom-control-label" for="unrestrictedFalseId">{{ 'AUTH.OSALINE_VOLITUS' |translate }}</label>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'rw' && ( appVolitusForm.value.unrestricted === 'false' || appVolitusForm.value.unrestricted === false)" class="form-group row mx-0 mb-0 mt-3 pb-0 z-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div>
                  <div class="d-block">
                    <div class="mt-2 w-100 custom-control custom-checkbox">
                      <input id="rightSignatureInputId" type="checkbox" class="custom-control-input" formControlName="rightSignature" />
                      <label class="custom-control-label" for="rightSignatureInputId">{{ 'AUTH.ALLKIRJASTAMINE' | translate }}</label>
                    </div>
                    <div class="invalid-feedback mt-1" *ngIf="volitusSubmitted && (appVolitusForm.controls?.rightSignature?.errors?.size > 0 || isOsalineVolitusMultiUnselectedError === true)">
                      {{'ERROR.MULTI_UNSELECTED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'rw' && ( appVolitusForm.value.unrestricted === 'false' || appVolitusForm.value.unrestricted === false)" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div>
                  <div class="d-block">
                    <div class="mt-2 w-100 custom-control custom-checkbox">
                      <input id="rightAuthorisationInputId" type="checkbox" class="custom-control-input" formControlName="rightAuthorisation" />
                      <label class="custom-control-label" for="rightAuthorisationInputId">{{ 'AUTH.VOLITUSE_LISAMINE' | translate }}</label>
                    </div>
                    <div class="invalid-feedback mt-1" *ngIf="volitusSubmitted && (appVolitusForm.controls?.rightAuthorisation?.errors?.size > 0 || isOsalineVolitusMultiUnselectedError === true)">
                      {{'ERROR.MULTI_UNSELECTED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'rw' && ( appVolitusForm.value.unrestricted === 'false' || appVolitusForm.value.unrestricted === false)" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div>
                  <div class="d-block">
                    <div class="mt-2 w-100 custom-control custom-checkbox">
                      <input id="rightNewvesselInputId" type="checkbox" class="custom-control-input" formControlName="rightNewvessel" />
                      <label class="custom-control-label" for="rightNewvesselInputId">{{ 'AUTH.UUE_LAEVA_REG' | translate }}</label>
                    </div>
                    <div class="invalid-feedback mt-1" *ngIf="volitusSubmitted && (appVolitusForm.controls?.rightNewvessel?.errors?.size > 0 || isOsalineVolitusMultiUnselectedError === true)">
                      {{'ERROR.MULTI_UNSELECTED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'rw' && ( appVolitusForm.value.unrestricted === 'false' || appVolitusForm.value.unrestricted === false)" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-md-15 col-xs-20 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-1 col-form-label text-right px-0">
                </label>
                <div>
                  <div class="d-block">
                    <div class="mt-2 w-100 col-sm-18 custom-control custom-checkbox">
                      <input id="rightActivitiesInputId" type="checkbox" class="custom-control-input" formControlName="rightActivities" (click)="refreshSelectPicker();" />
                      <label class="custom-control-label" for="rightActivitiesInputId">{{ 'AUTH.LAEVADEGA_TOIMUNUD_TEGEVUSED' | translate }}</label>
                    </div>
                    <div class="invalid-feedback mt-1" *ngIf="volitusSubmitted && (appVolitusForm.controls?.rightActivities?.errors?.size > 0 || isOsalineVolitusMultiUnselectedError === true)">
                      {{'ERROR.MULTI_UNSELECTED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <!--
             RO
          -->
          <div *ngIf="mode === 'ro' && !currentVolitusData?.unrestricted && currentVolitusData?.rightSignature" class="form-group row mx-0 mb-0 mt-3 pb-0 z-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">
                      {{ 'AUTH.ALLKIRJASTAMINE' |translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'ro' && !currentVolitusData?.unrestricted && currentVolitusData?.rightAuthorisation" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">
                      {{ 'AUTH.VOLITUSE_LISAMINE' |translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'ro' && !currentVolitusData?.unrestricted && currentVolitusData?.rightNewvessel" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">
                      {{ 'AUTH.UUE_LAEVA_REG' |translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'ro' && !currentVolitusData?.unrestricted && currentVolitusData?.rightActivities" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0">
                </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0">
                  <div class="d-flex h-100 mb-0 align-items-center">
                    <p class=" mb-0">
                      {{ 'AUTH.LAEVADEGA_TOIMUNUD_TEGEVUSED' |translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <div *ngIf="mode === 'ro' && !currentVolitusData?.unrestricted && currentVolitusData?.rightActivities && currentVolitusData?.autAuthorVesselsById?.length > 0 " class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-5 col-sm-2 col-form-label text-right px-0 pt-1">
                  {{'GLOBAL.SHIPS' | translate}}: </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pl-5 pt-1 mb-0 row">
                  <div class="row col-20 h-100 mb-0 pl-5  d-block">
                    <div class="col-20 pl-1 mb-0" *ngFor="let laev of currentVolitusData?.autAuthorVesselsById">
                      {{laev?.vesVesselByVesselId?.vesselName}} ({{laev?.vesVesselByVesselId?.currentRegistry?.claListitemByRegistry?.shortcode}}: {{laev?.vesVesselByVesselId?.currentRegistry?.regNumber}}) </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
          <!-- RW -->
          <div *ngIf="mode === 'rw' &&
           (appVolitusForm.value.unrestricted === 'false' || appVolitusForm.value.unrestricted === false ) &&
           appVolitusForm.value.rightActivities === true" class="form-group row mx-0 z-0 mb-0 pb-0">
            <div class="col-20 row mx-0 pr-0 pl-4 mb-0">
              <div class="paddingLeftFix col-15 row mx-0 pr-0 mb-0">
                <label class="col-xs-20 col-md-7 col-sm-2 col-form-label text-right px-0">
                  {{'GLOBAL.SHIPS' | translate}}: </label>
                <div class="col-xs-20 col-md-15 col-sm-18  pr-0 pl-1 mb-0 row d-block">
                  <div style="min-width: 200px;" class="col-20 ml-4">
                    <div class="w-100 d-block">
                      <div class="py-2" *ngIf="volitatudVessels?.length === 0">
                        <p class=" mb-0">
                          {{'AUTH.ALL_SHIPS_SELECTED' | translate}}
                        </p>
                      </div>
                      <div class="d-flex">
                        <!-- SELECT -->
                        <select style="min-width: 200px;" class="form-control w-100 selectpicker" id="select_vessel" formControlName="selectedVessel">
                          <option *ngFor="let vessel of currentVessels" [ngValue]="vessel">
                            {{vessel?.vesselName}}
                            {{vessel?.currentRegistry?.claListitemByRegistry?.shortcode ? '('+vessel?.currentRegistry?.claListitemByRegistry?.shortcode + ': ' + vessel?.currentRegistry?.regNumber + ')'  : '' }}
                          </option>
                        </select>
                        <div class="ml-2">
                          <!-- ADD -->
                          <button info type="button" custom-class="" [style]="'square'" [icon]="'with-plus'"
                            (click)="addSelectedVessel(this.appVolitusForm.controls.selectedVessel.value)"><span>{{'GLOBAL.ADD_THIS' | translate}}</span></button>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="invalid-feedback mt-1" *ngIf="volitusSubmitted && volitatudVessels?.length === 0">
                      {{'AUTH.VOLITAJA_MUST_SELECT_VESSELS_ERROR' | translate }}
                    </div> -->
                  </div>
                  <div class="ml-5 mt-2 d-block">
                    <div class="ml-2" *ngFor="let laev of volitatudVessels" class="col-20 d-flex mb-1 align-items-center">
                      <div class="col-13 px-0">
                        <p class=" mb-0">
                          {{laev?.vesselName}} ({{laev?.currentRegistry?.claListitemByRegistry?.shortcode}}: {{laev?.currentRegistry?.regNumber}}) </p>
                      </div>
                      <div class="col-3 px-0 d-flex justify-content-start">
                        <!-- REMOVE -->
                        <button danger [style]="'small'" custom-class="ml-2" type="button" (click)="removeSelectedVessel(laev)" title="{{ 'GLOBAL.DELETE'| translate }}" [icon]="'cancel'">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- /row -->
        </div> <!-- volituseOigusedSectionId END -->
        <!--
              DOCUMENT
          -->
        <div id="documentSectionId">
          <div class="section-title pr-0">
            <a class="section-collapse" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="!isCollapsed5">{{'DOC.DOCUMENT' | translate}}</a>
            <div class="title-line mb-0"></div>
          </div>
          <div [collapse]="isCollapsed5" id="volitatudId" class="mb-0 pb-4">
            <div *ngIf="docTableData && docTableData?.length === 0" class="d-flex justify-content-end">
              <div class="d-block text-right">
                <button *ngIf="mode === 'rw'" type="button" info [style]="'square'" [icon]="'with-plus'" (click)="openDocModal()"><span>{{ 'GLOBAL.ADD' | translate}}</span></button>
                <!-- ERRORS -->
                <div class="mt-1 invalid-feedback d-block">
                  <div class="invalid-feedback" *ngIf="env === 'mk' && volitusSubmitted && !docTableData.length">
                    {{'ERROR.DOC_REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="docTableData && docTableData?.length > 0" class="mt-3">
              <app-document-table [type]="'auth'" [docList]="docTableData" [mode]="mode" (viewDoc)="openDocModal($event)" (editDoc)="openDocModal($event)" (deleteDoc)="deleteDocument($event)">
              </app-document-table>
            </div>
            <div class="invalid-feedback text-right" *ngIf="env === 'mk' && volitusSubmitted && docTableData.length > 0 && !docTableData[0].fileOriginName">
              {{'ERROR.DOC_REQUIRED' | translate }}
            </div>
          </div>
        </div><!-- documentSectionId END -->
      </div>
    </div>
  </div>
  <!--
    FOOTER - BUTTONS
  -->
  <div class="formFooter col-20 py-4 d-flex justify-content-between">
    <div>
      <button light *ngIf="mode=== 'ro'" [style]="'square'" type="button" custom-class="" [icon]="'arrow-sm-left'" (click)="goBack()"><span>{{'GLOBAL.BACK' | translate}}</span></button>
    </div>
    <!--
       RW
    -->
    <div *ngIf="mode=== 'rw'">
      <!-- Kinnita -->
      <button [disabled]="hasVolitaja === false ||
        hasVolitatudIsik === false ||
        this.currentVolitajaFormData.personId === this.currentVolitatudFormData.personId" success [style]="'square'" type="button" custom-class="" (click)="saveVolitus()" [icon]="'save'">
        <span>{{ 'GLOBAL.CONFIRM' | translate}}</span>
      </button>
      <!-- Katkesta -->
      <button danger [style]="'square'" type="button" custom-class="ml-2" (click)="cancleVolitus()" [icon]="'cancel'">
        <span>{{ 'GLOBAL.LEAVE' | translate}}</span>
      </button>
    </div>
    <!--
       RO
    -->
    <div *ngIf="mode === 'ro'" class="d-flex">
      <button *ngIf="(volitusStatus === authStatus.kehtiv || volitusStatus === authStatus.loppend) && canEditAuthorization" info type="button" (click)="uueVolituseKoostamineTeisePealt()" custom-class="" [style]="'square'"
        [icon]="'square-pencil2'"><span>{{ volitusStatus === authStatus.kehtiv ? ('AUTH.BUTTON.MUUDAN_KEHTIVUST_JA_OIGUSI' | translate) : ('AUTH.BUTTON.LISAN_UUE_SAMALE' | translate)}}</span>
      </button>
      <!-- Tühista -->
      <button *ngIf="volitusStatus === authStatus.kehtiv && canEditAuthorization" danger [style]="'square'" type="button" custom-class="ml-2" (click)="openVolituseTuhistamineModal()" [icon]="'cancel'">
        <span>{{ 'AUTH.BUTTON.TUHISTAN' | translate}}</span>
      </button>
    </div>
  </div>
  <!--
    TEPLATES
  -->
  <!-- ADD PERSON -->
  <ng-template #addEditIsikTbl>
    <app-add-isik [isAuthorityDetailPage]="true" [mode]="addIsikMode" [showContactsFields]="true" [isUpdate]="updateIsikModalB" [ownershipType]="currentOwnershipType" [ownership]="currentOwnership"  (saveAuthPersonEv)="saveOrGetIsikMap($event)" (closeModalEv)="closeModal()"></app-add-isik>
  </ng-template>
  <!-- TÜHISTAN REASON MODAL TEMPLATE -->
  <ng-template #modalTuhistanVolituseTpl class="raised">
    <div class="modal-header pb-1">
      <h2 class="modalTitle">
        {{'AUTH.TUHISTAMINE_TITLE'| translate }}
      </h2>
      <button class="position-absolute right-0 top-0 d-table-cell m-2" light [style]="'round'" [icon]="'exit'" title="{{'GLOBAL.CLOSE' | translate}}" (click)="closeTuhistamineConfirmModal()"></button>
    </div>
    <div class="modal-body px-5">
      <div class="position-relative py-3 px-4 mt-3">
        <div>
          <div class="form-group row pb-0">
            <label class="col-5 col-form-label requiredField">{{ 'GLOBAL.REASON' | translate }}:</label>
            <div class="col-15 col-form-label font-weight-bold">
              <textarea [(ngModel)]="tuhistamineReason" [ngModelOptions]="{standalone: true}" class="form-control textarea" rows="4" [maxLength]="1000"></textarea>
              <div class="invalid-feedback" *ngIf="tuhistaVolitusSubbmit && (!tuhistamineReason || tuhistamineReason?.length === 0)">
                {{ 'ERROR.REQUIRED' | translate }}
              </div>
            </div>
          </div><!-- /row -->
          <div class="row pr-2 pb-2">
            <div class="col-20 d-flex justify-content-end">
              {{tuhistamineReason ? tuhistamineReason?.length : 0}}/1000 </div>
          </div><!-- /row -->
        </div>
        <div class="row">
          <div class="col-20 col-form-label">
            <div class="d-flex justify-content-end">
              <button success [style]="'square'" type="button" [icon]="'save'" (click)="tuhistaVolitus()">
                <span>{{ 'GLOBAL.CONFIRM' | translate }}</span> </button>
              <button danger custom-class="ml-2" [style]="'square'" type="button" [icon]="'cancel'" (click)="closeTuhistamineConfirmModal()">
                <span>{{ 'GLOBAL.LEAVE' | translate }}</span> </button>
            </div>
          </div>
        </div><!-- /row -->
      </div>
    </div>
  </ng-template>
  <ng-template #confirmModalTpl>
    <div class="mb-2">
      <div class="modal-header">
        <h3 class="font-weight-bold">
          <div *ngIf="hasSamePersonAlready">{{'AUTH.KINNITAMINE_WARNING_1'| translate}}</div>
          <div *ngIf="!hasSamePersonAlready">{{'AUTH.KINNITAMINE_WARNING_2'| translate}}</div>
        </h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-20">
            <div class="form-group pb-0">
              <div class="w-100 d-flex justify-content-end">
                <div class="mb-0">
                  <button success [style]="'square'" (click)="confirmWarning()" [icon]="'save'">
                    <span>{{ 'GLOBAL.YES' | translate }}</span>
                  </button>
                </div>
                <div class="ml-2 mb-0">
                  <button danger [style]="'square'" type="button" (click)="closeConfirmModal()" [icon]="'cancel'">
                    <span>{{ 'GLOBAL.NO' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.row -->
      </div><!-- /.modal-body -->
    </div>
  </ng-template>

  <ng-template #isikDetailTpl>
    <div class="modal-header pb-1">
      <h2 class="" *ngIf="currentPerson.personType !== 'J'">
        {{ 'USERDATA.FISIK' | translate }}
      </h2>
      <h2 class="" *ngIf="currentPerson.personType === 'J'">
        {{ 'USERDATA.JISIK' | translate }}
      </h2>
      <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}" (click)="closeModal2()"></button>
    </div>
    <div class="modal-body">
      <div class="form-group mx-0 pb-0" *ngIf="currentPerson.personType === 'J'">
        <app-formgroup-row label="{{ 'USERDATA.RIIK' | translate }}">{{ countryMap.get(currentPerson.personCountryId)
          }}
        </app-formgroup-row>
        <app-formgroup-row label="{{ 'USERDATA.RKOOD' | translate }}">
          {{ currentPerson.personRegCode }}
        </app-formgroup-row>
        <app-formgroup-row label="{{ 'USERDATA.ANIMI' | translate }}">
          {{ currentPerson.personName }}
        </app-formgroup-row>
        <div *ngIf="personInfo?.data">
          <div *ngFor="let adr of personInfo.data.addresses; let x = index">
            <app-formgroup-row label="{{ 'USERDATA.AADRESS' | translate }}">
              {{ adr }}
            </app-formgroup-row>
          </div>
          <div *ngFor="let email of personInfo.data.emails; let y = index">
            <app-formgroup-row label="{{ 'USERDATA.EPOST' | translate }}">
              {{ email }}
            </app-formgroup-row>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="form-group mx-0 pb-0" *ngIf="currentPerson.personType === 'F'">
        <app-formgroup-row label="{{ 'USERDATA.RIIK' | translate }}">
          {{ countryMap.get(currentPerson.personCountryId)
          }}
        </app-formgroup-row>
        <app-formgroup-row label="{{ 'USERDATA.IKOOD' | translate }}">
          {{ currentPerson.personRegCode }}
        </app-formgroup-row>
        <app-formgroup-row label="{{ 'USERDATA.ANIMI' | translate }}">
          {{ currentPerson.personFirstName }} {{ currentPerson.personName }}
        </app-formgroup-row>
        <div *ngIf="personInfo?.data">
          <div *ngFor="let adr of personInfo.data.addresses; let x = index">
            <app-formgroup-row label="{{ 'USERDATA.AADRESS' | translate }}">
              {{ adr }}
            </app-formgroup-row>
          </div>
          <div *ngFor="let email of personInfo.data.emails; let y = index">
            <app-formgroup-row label="{{ 'USERDATA.EPOST' | translate }}">
              {{ email }}
            </app-formgroup-row>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
